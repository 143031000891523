import React, {useState} from 'react';

const AccordionSubItem = ({isLinked}) => {
    return (
        <div
            className="w-full flex items-center px-4 py-3">
            <span className="font-medium text-sm">Keyword 1</span>
            <span
                className="ml-6 font-medium me-2 px-2.5 py-0.5 rounded text-blue-600 underline text-sm">
                            https://www.leafyorb.com/blogs/article-1
                        </span>
            {isLinked && <span
                className="ml-6 font-medium me-2 px-2.5 py-0.5 rounded text-gray-600 text-sm">
                            13 Clicks
                        </span>}
            {isLinked && <span
                className="ml-6 font-medium me-2 px-2.5 py-0.5 rounded text-gray-600 text-sm">
                            10 Impressions
                        </span>}
            <span
                className="ml-6 font-medium me-2 px-2.5 py-0.5 rounded text-green-600 text-sm">
                            301 Status
                        </span>
            <div className="flex ml-auto">
                
                {isLinked ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                         className="w-5 h-5 mr-3 text-green-500">
                        <path fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                              clipRule="evenodd"/>
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-5 h-5 mr-3">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                }
                
                
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
            </div>
        </div>
    );
};

export default AccordionSubItem;
