import { Intercom } from '@intercom/messenger-js-sdk';
import intercomAppId from "../config/intercom_config";
export function initializeIntercom(user = {}) {
    if (typeof window === "undefined") return;
    try {
      Intercom({
        app_id: intercomAppId,
        ...user,
      });
    } catch (error) {
      console.error("Error initializing Intercom:", error);
    }
  }
