import React, {useState} from 'react'
import Accordion from "../components/layouts/Accordion";
import AppModalLong from "../components/shared/AppModalLong";
import TwitterIcon from "../assets/img/twitter.png";
import FacebookIcon from "../assets/img/facebook.png";
import InstagramIcon from "../assets/img/instagram.png";
import TopNav from "../components/layouts/TopNav";
import SecondaryTopNav from "../components/layouts/SecondaryTopNav";


function InternalLink() {
    
    const [isModalOpen, setIsModalOpen] = useState();
    
    const handleOpenModal = () => {
        setIsModalOpen(true);
    }
    
    const handleCloseModal = () => {
        setIsModalOpen(false);
    }
    
    return (
        <div>
                 <TopNav/>
        
        <SecondaryTopNav/>

            <div className="max-w-6xl px-6 mx-auto mb-28">
                <div className="flex mt-6">
                    <div className="ml-2">
                        <h1 className="text-2xl font-bold">Internal Link</h1>
                    </div>
                    
                    <button type="button"
                            className="ml-auto text-white bg-orange-600 font-bold rounded text-xs px-5 py-1 mr-3 text-center"
                            onClick={handleOpenModal}>
                        Save
                    </button>
                </div>
                
                <Accordion/>
                
                
                {/* <button type="button"
                        className="ml-auto text-white bg-orange-600 font-bold rounded text-xs px-6 py-2 mr-3 text-center"
                        onClick={handleOpenModal}>
                    Content Repurpose
                </button>
                
                {isModalOpen && (
                    <AppModalLong title={""}>
                        <div className="">
                            <div className="flex justify-between py-3 px-1 text-sm text-bold">
                                <div className="text-left">
                                    <h2 className="text-xl font-bold">Content Repurpose</h2>
                                </div>
                                
                                <div onClick={handleCloseModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </div>
                            </div>
                            
                            
                            <div className="mt-3">
                                
                                <div className="mb-8">
                                    <div className="text-lg font-bold ml-1">
                                        Experience the 3D Maze Illusion: The Ultimate Tough Case for Samsung!
                                    </div>
                                    <div className="text-gray-600 dark:text-white mb-2 ml-1">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna adivqua. consectetur adipiscing edivt.
                                    </div>
                                </div>
                                
                                <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                                    <div
                                        className="items-center rounded-lg border border-gray-200 bg-white py-2">
                                        <div className="flex mt-1 px-4">
                                            <div>
                                                <span
                                                    className="text-sm font-bold text-gray-600 dark:text-white">Facebook</span>
                                            </div>
                                            
                                            <div className="ml-auto flex mt-0.5 cursor-pointer">
                                                <div className="flex mr-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-4 h-4 mr-1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                                    </svg>
                                                    <span
                                                        className="text-xs font-bold text-gray-600 dark:text-white">Regenerate</span>
                                                </div>
                                                
                                                <div className="flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         strokeWidth="1.5" stroke="currentColor"
                                                         className="w-4 h-4 mr-1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"/>
                                                    </svg>
                                                    <span
                                                        className="text-xs font-bold text-gray-600 dark:text-white">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="my-3">
                                            <hr/>
                                        </div>
                                        
                                        <div className="pb-2 relative">
                                            <div className="text-sm text-gray-600 dark:text-white mb-2 px-4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna adivqua. consectetur
                                                adipiscing edivt.
                                            </div>
                                            <div className="text-sm text-gray-600 dark:text-white mb-2 px-4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna adivqua.
                                            </div>
                                            <div className="relative">
                                                <img src="https://placehold.co/600x400" alt="Product Image"
                                                     className="product-image"/>
                                                <button type="button"
                                                        className="absolute bottom-4 right-4 text-white bg-gray-500 font-bold rounded text-xs px-6 py-2"
                                                        onClick={handleOpenModal}>
                                                    Download Image
                                                </button>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    
                                    
                                    <div
                                        className="items-center rounded-lg border border-gray-200 bg-white py-2">
                                        <div className="flex mt-1 px-4">
                                            <div>
                                                <span
                                                    className="text-sm font-bold text-gray-600 dark:text-white">Twitter</span>
                                            </div>
                                            
                                            <div className="ml-auto flex mt-0.5 cursor-pointer">
                                                <div className="flex mr-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-4 h-4 mr-1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                                    </svg>
                                                    <span
                                                        className="text-xs font-bold text-gray-600 dark:text-white">Regenerate</span>
                                                </div>
                                                
                                                <div className="flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         strokeWidth="1.5" stroke="currentColor"
                                                         className="w-4 h-4 mr-1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"/>
                                                    </svg>
                                                    <span
                                                        className="text-xs font-bold text-gray-600 dark:text-white">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="my-3">
                                            <hr/>
                                        </div>
                                        
                                        <div className="pb-2 relative">
                                            <div className="text-sm text-gray-600 dark:text-white mb-2 px-4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna adivqua. consectetur
                                                adipiscing edivt.
                                            </div>
                                            <div className="text-sm text-gray-600 dark:text-white mb-2 px-4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna adivqua.
                                            </div>
                                            <div className="relative">
                                                <img src="https://placehold.co/600x400" alt="Product Image"
                                                     className="product-image"/>
                                                <button type="button"
                                                        className="absolute bottom-4 right-4 text-white bg-gray-500 font-bold rounded text-xs px-6 py-2"
                                                        onClick={handleOpenModal}>
                                                    Download Image
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div
                                        className="items-center rounded-lg border border-gray-200 bg-white py-2">
                                        <div className="flex mt-1 px-4">
                                            <div>
                                                <span
                                                    className="text-sm font-bold text-gray-600 dark:text-white">LinkedIn</span>
                                            </div>
                                            
                                            <div className="ml-auto flex mt-0.5 cursor-pointer">
                                                <div className="flex mr-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-4 h-4 mr-1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                                    </svg>
                                                    <span
                                                        className="text-xs font-bold text-gray-600 dark:text-white">Regenerate</span>
                                                </div>
                                                
                                                <div className="flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         strokeWidth="1.5" stroke="currentColor"
                                                         className="w-4 h-4 mr-1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"/>
                                                    </svg>
                                                    <span
                                                        className="text-xs font-bold text-gray-600 dark:text-white">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="my-3">
                                            <hr/>
                                        </div>
                                        
                                        <div className="pb-2 relative">
                                            <div className="text-sm text-gray-600 dark:text-white mb-2 px-4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna adivqua. consectetur
                                                adipiscing edivt.
                                            </div>
                                            <div className="text-sm text-gray-600 dark:text-white mb-2 px-4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna adivqua.
                                            </div>
                                            <div className="relative">
                                                <img src="https://placehold.co/600x400" alt="Product Image"
                                                     className="product-image"/>
                                                <button type="button"
                                                        className="absolute bottom-4 right-4 text-white bg-gray-500 font-bold rounded text-xs px-6 py-2"
                                                        onClick={handleOpenModal}>
                                                    Download Image
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </AppModalLong>
                )} */}
            
            </div>
        </div>
    )
}

export default InternalLink
