import React, {useState} from "react";

const DeleteEditorSection = ({editors, editor, setSelectedEditor}) => {
    
    const deleteEditor = id => {
        const updatedEditors = editors.filter(editor => editor.id !== id);
        setSelectedEditor(updatedEditors);
    };

const [showConfirmDialog, setShowConfirmDialog] = useState(false);

const handleConfirmDelete = () => {
    deleteEditor(editor.id);
    setShowConfirmDialog(false);
};

const handleCancelDelete = () => {
    setShowConfirmDialog(false);
};

    return (
        <>
        <div
            className="mr-2"
            onClick={() => setShowConfirmDialog(true)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
            </svg>
        </div>

     {showConfirmDialog && (
                <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
                        <p className="mb-4">Are you sure you want to delete this section? This Action is irreversible</p>
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                onClick={handleCancelDelete}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-700 text-white px-4 py-2 rounded"
                                onClick={handleConfirmDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
};

export default DeleteEditorSection;


// const [showConfirmDialog, setShowConfirmDialog] = useState(false);

// const handleDeleteClick = () => {
//     setShowConfirmDialog(true);
// };

// const handleConfirmDelete = () => {
//     deleteEditor(editor.id);
//     setShowConfirmDialog(false);
// };

// const handleCancelDelete = () => {
//     setShowConfirmDialog(false);
// };


     {/* {showConfirmDialog && (
                <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
                        <p className="mb-4">Are you sure you want to delete this section?</p>
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-200 text-gray-800 px-4 py-2 rounded mr-2"
                                onClick={handleCancelDelete}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={handleConfirmDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )} */}


                
    // const handleDeleteClick = () => {
    //     if (window.confirm("Are you sure you want to delete this section?")) {
    //         deleteEditor(editor.id);
    //     }
    // };
