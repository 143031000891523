import React, {useContext, useEffect, useState} from "react";
import ArticleTypeSelector from "./ArticleTypeSelector";
import CreateOwnArticleForm from "./CreateOwnArticleForm";
import StepOneAISettings from "./StepOneAISettings";
import StepTwoKeywords from "./StepTwoKeywords";
import StepFourTitleSuggestions from "./StepFourTitleSuggestions";
import StepThreeWordCount from "./StepThreeWordCount";
import StepFiveForm from "./StepFiveForm";
import API from "../../api/api";
import AppContext from "../../context/AppContext";
import {useLocation} from "react-router-dom";
import {toastError} from "../../context/ToasterContext";
import {ROUTE_CONSTANT} from "../../constants/app.constants";
import {useNavigate} from "react-router";
import {Toaster} from "react-hot-toast";
import mixpanel from "mixpanel-browser";

const CreateArticlePager = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [radioValue, setRadioValue] = useState("");
    const [isTopicEmpty, setIsTopicEmpty] = useState(false);
    const [isKeywordEmpty, setIsKeywordEmpty] = useState(false);
    const [isWordCountEmpty, setIsWordCountEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const location = useLocation();
    const navigate = useNavigate();
    const {blog_id, blog_uuid, blog_handle, blog_title} = location.state;
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    
    const handleOptionClick = (value) => {
        setRadioValue(value);
        setCurrentPage(1);
    };
    
    const [formData, setFormData] = useState({
        articleName: '',
        articleSlug: '',
        articleAuthor: '',
        articleCategory: ''
    });
    
    const [aiSettingsFormData, setAISettingsFormData] = useState({
        topic: '',
        language: 'en-US',
        model: 'gpt-3.5-turbo',
        location: '',
        keywords: '',
        wordcount: '',
        tone: '',
        pov: '',
        voice: '',
        additional_prompt: "",
        include_conclusion: false,
        conclusion: false,
        article_type: 'general',
        generateImage: false,
        generateFAQ: false,
        generateConclusion: false,
        metaTitle: false,
        metaDescription: false,
        article_title: ""
    });
    
    
    const handleFormData = () => {
        const payload = {
            title: formData.articleName,
            category: "",
            tags: formData.articleAuthor,
            author: formData.articleCategory
        }
        handleGenerateArticle(payload);
    };
    
    const handleAISettingsFormData = () => {
        const payload = {
            language: aiSettingsFormData.language,
            location: aiSettingsFormData.location,
            target_keyword: aiSettingsFormData.keywords,
            article_title: aiSettingsFormData.article_title,
            openai_key: "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",
            additional_prompt: aiSettingsFormData.additional_prompt,
            is_generate_image: aiSettingsFormData.generateImage,
            conclusion: aiSettingsFormData.generateConclusion,
            generate_faq_section: aiSettingsFormData.generateFAQ,
            meta_description: aiSettingsFormData.metaDescription,
            meta_title: aiSettingsFormData.metaTitle,
            aiArticleUUID: aiSettingsFormData.article_id,
            meta_data: {
                model: aiSettingsFormData.model,
                tone: aiSettingsFormData.tone,
                article_type: aiSettingsFormData.article_type,
                word_count_type: aiSettingsFormData.wordcount,
            }
        }
        handleGenerateAIArticle(payload);
    };
    
    const handleGenerateAIArticle = (payload) => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.post(`article-generator/generate-article/${account}/${store}/${blog_uuid}`, payload, headerParams)
            .then(res => {
                const {status} = res.data;
                if (status === 'success') {
                    navigate(`${ROUTE_CONSTANT.GENERATING_ARTICLE_PAGE}?article_id=${aiSettingsFormData.article_id}`, {
                        state: {
                            blog_id: blog_id,
                            blog_uuid: blog_uuid,
                            blog_title: blog_title,
                            blog_handle: blog_handle
                        }
                    });
                }
                mixpanel.track('Create New Article', {
                    "is_AI": true,
                    "article_title": payload.title,
                });
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
                setLoading(false);
            });
    }
    
    const handleGenerateArticle = (payload) => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.post(`articles/create-article/${account}/${store}/${blog_uuid}`, payload, headerParams)
            .then(res => {
                navigate(`/blogs/${blog_handle}/articles`, {
                    state: {
                        blog_id: blog_id,
                        blog_uuid: blog_uuid,
                        blog_title: blog_title,
                        blog_handle: blog_handle
                    }
                });
                mixpanel.track('Create New Article', {
                    "is_AI": false,
                    "title": payload.title,
                    "category": payload.category,
                    "tags": payload.tags,
                    "author": payload.author
                });
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
                setLoading(false);
            });
    }
    
    const pages = [
        <ArticleTypeSelector handleOptionClick={handleOptionClick}/>,
        ...(radioValue === 'own' ? [
            <CreateOwnArticleForm
                key="create-own"
                formData={formData}
                setFormData={setFormData}/>] : []),
        <StepOneAISettings
            aiSettingsFormData={aiSettingsFormData}
            setAISettingsFormData={setAISettingsFormData}
            isTopicEmpty={isTopicEmpty}
            setIsTopicEmpty={setIsTopicEmpty}/>,
        <StepTwoKeywords
            aiSettingsFormData={aiSettingsFormData}
            setAISettingsFormData={setAISettingsFormData}
            isKeywordEmpty={isKeywordEmpty}
            setIsKeywordEmpty={setIsKeywordEmpty}
        />,
        <StepThreeWordCount
            aiSettingsFormData={aiSettingsFormData}
            setAISettingsFormData={setAISettingsFormData}
            isWordCountEmpty={isWordCountEmpty}
            setIsWordCountEmpty={setIsWordCountEmpty}
        />,
        <StepFourTitleSuggestions
            aiSettingsFormData={aiSettingsFormData}
            setAISettingsFormData={setAISettingsFormData}/>,
        <StepFiveForm
            aiSettingsFormData={aiSettingsFormData}
            setAISettingsFormData={setAISettingsFormData}/>,
    ];
    
    useEffect(() => {
        console.log("current page", currentPage);
    }, [currentPage]);
    
    
    const isLastPage = () => {
        if (radioValue === "own" && currentPage === 1) return true;
        return radioValue === "ai" && currentPage === 5;
    };
    
    const handleNextPage = () => {
        if (currentPage === 1 && !aiSettingsFormData.topic) {
            setIsTopicEmpty(true);
            return;
        }
        if (currentPage === 2 && !aiSettingsFormData.keywords) {
            setIsKeywordEmpty(true);
            return;
        }
        if (currentPage === 3 && !aiSettingsFormData.wordcount) {
            setIsWordCountEmpty(true);
            return;
        }
        setCurrentPage((prev) => prev + 1);
    };
    
    return (
        <div className="">
            <div className="bg-white rounded-lg border border-gray-200 shadow-lg p-6">
                {pages[currentPage]}
            </div>
            
            <div className="flex mt-4 float-right">
                {currentPage > 0 && (
                    <button
                        onClick={() => {
                            setCurrentPage((prev) => prev - 1)
                        }}
                        className={`border border-gray-400 hover:bg-gray-100 mr-3 text-gray-600 font-semibold text-sm py-2 px-8 w-36 rounded-lg ${
                            currentPage === 0
                                ? "bg-gray-300 cursor-not-allowed"
                                : "bg-gray-500 hover:bg-gray-700 text-white"
                        }`}
                        disabled={currentPage === 0}
                    >
                        Previous
                    </button>
                )}
                {isLastPage() ? (
                    <>
                        {radioValue === 'own' ?
                            <button
                                onClick={handleFormData}
                                disabled={!formData.articleName || !formData.articleSlug}
                                className={`bg-primary hover:bg-orange-600 text-white font-semibold text-sm py-2 px-8 w-40 rounded-lg ${loading ? 'cursor-not-allowed' : ''}`}
                            >
                                {loading ? "Creating..." : "Create Article"}
                            </button> :
                            <button
                                onClick={() => {
                                    handleAISettingsFormData();
                                }}
                                disabled={loading}
                                className={`bg-primary hover:bg-orange-600 text-white font-semibold text-sm py-2 px-8 w-40 rounded-lg ${loading ? 'cursor-not-allowed' : ''}`}
                            >
                                {loading ? "Creating..." : "Create Article"}
                            </button>}
                    </>
                ) : (
                    <>
                        {currentPage > 0 && <button
                            onClick={handleNextPage}
                            className={`bg-primary text-white font-semibold text-sm py-2 px-8 w-36 rounded-lg ${
                                currentPage === pages.length - 1
                                    ? "bg-gray-300 cursor-not-allowed"
                                    : "bg-orange-500 hover:bg-orange-700 text-white"
                            }`}
                            disabled={currentPage === pages.length - 1}
                        >
                            Next
                        </button>}
                    </>
                )}
            </div>
            
            <Toaster/>
        </div>
    );
};

export default CreateArticlePager;
