import React, {useContext, useEffect, useState} from 'react'
import PageTitle from "../components/typography/PageTitle";
import {useNavigate} from "react-router";
import API from "../api/api";
import AppContext from "../context/AppContext";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import EmptyImage from "../assets/img/empty-image.png";
import OBTopNav from "../components/layouts/OBTopNav";
import AppModalShort from "../components/shared/AppModalShort";
import DeployForm from "../components/shared/DeployForm";
import AppModalLong from "../components/shared/AppModalLong";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import ChooseTemplate from "./ChooseTemplate";
import Lottie from "react-lottie";
import BlogEditingLottie from "../assets/lotties/Blog_editing.json";
import moment from "moment/moment";
import AppModalMedium from "../components/shared/AppModalMedium";
import {toastError} from "../context/ToasterContext";

export default function AllBlogs() {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    const [allBlogs, setAllBlogs] = useState([]);
    const [shopifyBlogs, setShopifyBlogs] = useState([]);
    const [allImportedBlogs, setAllImportedBlogs] = useState([]);
    const [deployedBlogs, setDeployedBlogs] = useState([]);
    const [notDeployedBlogs, setNotDeployedBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    
    const [blogTitle, setBlogTitle] = useState("");
    const [isExistingBlogClicked, setIsExistingBlogClicked] = useState(false);
    
    const location = useLocation();
    const [article, setArticle] = useState({});
    const [articleHtml, setArticleHtml] = useState("");
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [isPublishSelected, setIsPublishSelected] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);
    
    const handleSelectBlog = (blog) => {
        setSelectedBlog(blog);
    };
    
    const defaultOptions = {
        loop: true, autoplay: true, animationData: BlogEditingLottie, rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    const handleOpenExistingBlogModal = () => {
        setIsExistingBlogClicked(true);
    };
    
    const handleCloseExistingBlogModal = () => {
        setIsExistingBlogClicked(false);
    };
    
    const handleClosePublishModal = () => {
        setIsPublishModalOpen(false);
    };
    
    const handleCreateBlog = () => {
        createBlog();
    }
    
    const publishSelectedStatus = (status) => {
        setIsPublishSelected(status)
    }
    
    const showSEO = (value) => {
    }
    
    const handleMigrationBlog = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.post(`blog/migrate-articles/${account}/${store}`, selectedBlog, headerParams)
            .then(res => {
                const {data} = res.data;
                //update local storage
                localStorage.setItem("blogType", "new");
                navigate(`/blogs/${selectedBlog.handle}/articles?migrate=true`, {
                    state: {
                        blog_id: selectedBlog.id,
                        blog_uuid: data.blog_uuid,
                        blog_title: selectedBlog.title,
                        blog_handle: selectedBlog.handle
                    }
                });
            })
            .catch(error => {
                toastError(error.message);
            })
    }
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                if (store === undefined || store === null || store === "") {
                    setAllBlogs([]);
                } else {
                    if (localStorage.getItem("blogType") === "migrate") {
                        handleOpenExistingBlogModal();
                        handleFetchShopifyBlogs(account);
                    } else {
                        handleFetchBlog(account);
                    }
                }
                if (location.search) {
                    createTempStore(user.id);
                }
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user, store]);
    
    
    const createTempStore = (userId) => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            userId: userId
        }
        API.post(`shopify/setup-temp-store`, bodyParams, headerParams)
            .then(res => {
            })
            .catch(error => {
                console.log('Error: ', error.message);
            })
    }
    
    const createBlog = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            blog_name: blogTitle
        }
        API.post(`shopify/blog/create/${account}/${store}`, bodyParams, headerParams)
            .then(res => {
            })
            .catch(error => {
                console.log('Error: ', error.message);
            })
    }
    
    const handleFetchShopifyBlogs = (account) => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`shopify/blogs/${account}/${store}`, headerParams)
            .then(res => {
                console.log("res.data.data", res.data.data);
                setShopifyBlogs(res.data.data);
                setLoading(false);
            })
            .catch(error => {
                setShopifyBlogs([]);
                setLoading(false);
                setError(error.message);
            })
    }
    
    const handleFetchBlog = (account) => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`blog/list-blogs/${account}/${store}`, headerParams)
            .then(res => {
                setAllBlogs(res.data.data);
                setLoading(false);
            })
            .catch(error => {
                setAllBlogs([]);
                setLoading(false);
                setError(error.message);
            })
    }
    
    
    return (<div>
        
        <OBTopNav/>
        
        <div className="mt-6 tracking-wide mb-36">
            
            {!isPublishSelected && <div className="max-w-6xl mx-auto my-auto px-6 mb-8">
                
                {loading && <div className="flex justify-center text-center items-center mt-44">
                    <div className="text-center">
                        <div role="status" className={"text-center ml-8 mb-3"}>
                            <svg aria-hidden="true"
                                 className="w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>}
                
                {allBlogs.length >= 1 && <>
                    <div className="flex justify-between items-center">
                        {!loading && (<div className="">
                            <PageTitle>
                                We've uncovered <span
                                className="text-orange-600">{allBlogs.length} linked blogs</span> for your
                                store, <br/>enhance their efficiency with our provided features.
                            </PageTitle>
                        </div>)}
                        
                        <div className="text-center mt-2">
                            <div
                                className="bg-primary text-sm hover:bg-orange-600 cursor-pointer text-white inline-block font-bold py-2 px-8 rounded-lg"
                                onClick={() => {
                                    navigate(ROUTE_CONSTANT.CREATE_BLOG);
                                }}>
                                Create New Blog
                            </div>
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-3">
                        {allBlogs && allBlogs.map((item) => (<div
                            key={item.id}
                            className="relative w-full p-6 flex bg-white border border-gray-200 shadow-lg rounded-lg hover:border-gray-300"
                        >
                            <div className="">
                                <div
                                    className="p-1 text-[19px] font-bold text-gray-600 capitalize">
                                    {item.title} Blog
                                </div>
                        
                                <div
                                    className="pt-1 pl-1 text-[14px] text-gray-600 mb-2">
                                    Last update on {moment(item.updated_at).format("DD MMM YYYY")}
                                </div>
                                {item.blogd_domain ? <div
                                    className="pt-1 pl-1 text-[14px]">
                                    <a href={`https://${item.blogd_domain}`}
                                       target="_blank" rel="noreferrer">https://{item.blogd_domain}</a>
                                </div>
                                :
                                <div className='text-gray-400'>
                                Not yet published
                            </div>}
                                <div className="p-1 text-gray-600 mt-1">
                                    <button
                                        onClick={() => {
                                            localStorage.setItem("blog_uuid", item.uuid);
                                            localStorage.setItem("blog_handle", item.handle);
                                            navigate(`/blogs/${item.handle}/articles`, {
                                                state: {
                                                    blog_id: item.id,
                                                    blog_uuid: item.uuid,
                                                    blog_title: item.title,
                                                    blog_handle: item.handle
                                                }
                                            })
                                        }}
                                        className="text-primary mt-4 text-xs text-white font-bold underline uppercase rounded-lg">
                                        View Articles
                                    </button>
                                </div>
                                <div className="absolute right-3 text-[140px] font-bold text-gray-200 capitalize"
                                     style={{bottom: "-53px"}}>
                                    {_.head(item.title)}
                                </div>
                            </div>
                        </div>))}
                    </div>
                </>}
                
                {/* {!loading && <>
                    {allBlogs.length > 0 &&
                        <div className="fixed bottom-8 inset-x-0 text-center">
                            <div className="mt-12 flex mx-auto text-center justify-center">
                                <button
                                    onClick={() => {
                                        if (selectedBlog) {
                                            handleMigrationBlog();
                                        }
                                    }}
                                    className={`uppercase font-bold py-4 px-16 rounded-lg shadow ${selectedBlog ? "bg-primary hover:bg-orange-600 text-white" : "bg-primary bg-opacity-75 text-white cursor-not-allowed"}`}>
                                    Migrate
                                </button>
                            </div>
                        </div>
                    }
                </>} */}
                
                
                {allBlogs.length < 1 && <>
                    {!loading && <div className="flex text-center items-center justify-center -mt-16 min-h-screen">
                        <div>
                            <div className="flex justify-center items-center">
                                <Lottie
                                    style={{"margin": "none"}}
                                    options={defaultOptions}
                                    height={140}
                                    width={140}
                                />
                            </div>
                            <p className="font-bold text-gray-600 mt-8 text-center">Create Your First Blog</p>
                            <p className="text-gray-600 mt-3 text-center">Unleash your thoughts, transform your
                                ideas<br/> into a masterpiece — start your blog today!</p>
                            <div className="flex justify-center">
                                <button
                                    onClick={() => {
                                        navigate(ROUTE_CONSTANT.CREATE_BLOG);
                                    }}
                                    className="bg-primary hover:bg-orange-600 text-white font-bold py-2 px-8 rounded-lg mt-6">
                                    Create Blog
                                </button>
                            </div>
                        </div>
                    </div>}
                </>}
                
                {isExistingBlogClicked && (<AppModalShort title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-3 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Migrate Your Blog from <span className="text-green-500">Shopify</span></h2>
                            </div>
                            
                            <div onClick={handleCloseExistingBlogModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="p-2">
                            
                            <div className="mx-auto bg-white rounded-xl border border-gray-200 shadow-sm py-2">
                                <div className="">
                                    {shopifyBlogs.map((blogObj, index) => {
                                        const blog = blogObj;
                                        const blogUrl = `https://${store}/blogs/${blog.handle}`;
                                        
                                        return (
                                            <label key={index} className="flex mx-3 my-1 px-6 py-3 items-center cursor-pointer hover:bg-gray-100 hover:rounded-lg">
                                                <input
                                                    type="radio"
                                                    name="blog"
                                                    value={blog.id}
                                                    checked={selectedBlog?.id === blog.id}
                                                    onChange={() => handleSelectBlog(blog)}
                                                    className="mr-6"
                                                />
                                                <div>
                                                    <p className="text-gray-800 capitalize">{blog.title}</p>
                                                    <a href={blogUrl} className="text-blue-500 underline text-sm"
                                                       target="_blank" rel="noopener noreferrer">
                                                        {blogUrl}
                                                    </a>
                                                </div>
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                            
                            <div className="flex justify-center">
                                <button type="button"
                                        onClick={() => {
                                            if (selectedBlog) {
                                                handleMigrationBlog();
                                            }
                                        }}
                                        className={`font-bold py-2 px-8 rounded-lg mx-auto shadow mt-4 ${selectedBlog ? "bg-primary bg-opacity-75 text-white" : "bg-gray-400 text-white cursor-not-allowed"}`}>
                                    Migrate
                                </button>
                            </div>
                        
                        </div>
                    </div>
                </AppModalShort>)}
                
                
                {isPublishModalOpen && (<AppModalLong title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Publish Blog</h2>
                            </div>
                            
                            <div onClick={() => {
                                handleClosePublishModal();
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="pb-1 px-1">
                            
                            <DeployForm
                                article={article}
                                article_html={articleHtml}
                                handleClosePublishModal={() => {
                                    handleClosePublishModal();
                                }}
                                article_id={""}
                            />
                        
                        </div>
                    
                    </div>
                </AppModalLong>)}
            
            
            </div>}
            
            {/*{isPublishSelected && <div className="max-w-6xl mx-auto my-auto px-6 mb-8">
                <ChooseTemplate
                    isMigrate={true}
                    blog={selectedBlog}
                    isRouteChange={true}
                    showSEO={showSEO}
                    publishSelectedStatus={publishSelectedStatus}
                    fromSetting={false}
                />
            </div>}*/}
        
        </div>
    </div>)
}
