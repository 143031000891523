import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import API from "../api/api";
import AppContext from "../context/AppContext";
import _ from "lodash";
import {AUTH, ROUTE_CONSTANT, STORE_UUID} from "../constants/app.constants";
import OBTopNav from "../components/layouts/OBTopNav";
import {toastError, toastSuccess} from "../context/ToasterContext";
import mixpanel from "mixpanel-browser";

export default function CreateBlogPage() {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, triggerConfetti} = useContext(AppContext);
    
    const [blogName, setBlogName] = useState("");
    const [blogSlug, setBlogSlug] = useState("");
    const [pageOne, setPageOne] = useState(true);
    const [isDomainAvailable, setIsDomainAvailable] = useState(null);
    const [checkDomain, setCheckDomain] = useState(false);
    const [isCreateBlogClicked, setIsCreateBlogClicked] = useState(false);
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    const formik = useFormik({
        initialValues: {
            blogName: '',
            blogSlug: ''
        },
        validationSchema: Yup.object({
            blogName: Yup.string().required('Blog name is required'),
            blogSlug: Yup.string().required('Blog slug is required')
        }),
        onSubmit: values => {
            createNewBlog(values);
        },
    });
    
    const callApi = (formattedText) => {
        checkDomainAvailability(formattedText);
    };
    
    const [debouncedCallApi] = useState(() => _.debounce(callApi, 1500));
    
    const handleBlogNameInputChange = (event) => {
        setCheckDomain(true);
        const text = event.target.value;
        formik.setFieldValue('blogName', text);
        setBlogName(text);
        const formattedText = _.kebabCase(text);
        formik.setFieldValue('blogSlug', formattedText);
        setBlogSlug(formattedText);
        debouncedCallApi(formattedText);
    };
    
    const createNewBlog = () => {
        setIsCreateBlogClicked(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "blog_name": blogName,
            "blogd_domain": blogSlug,
        }
        API.post(`blog/create/${account}/${store}`, params, headerParams)
            .then(res => {
                const blog = res.data.data;
                localStorage.setItem("blog_uuid", blog.uuid);
                localStorage.setItem("blog_handle", blog.handle);
                navigate(`/blogs/${blog.handle}/articles`, {
                    state: {
                        blog_id: blog.id,
                        blog_uuid: blog.uuid,
                        blog_title: blog.title,
                        blog_handle: blog.handle
                    }
                });
                mixpanel.track('Create New Blog', {
                    'store': store,
                    'account': account,
                    'blog_name': blogName,
                    'blogd_domain': blogSlug,
                });
                setIsCreateBlogClicked(false);
                setIsDomainAvailable(true);
                toastSuccess("Blog Created");
                triggerConfetti();
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setIsCreateBlogClicked(false);
                setIsDomainAvailable(false);
                toastError(error.message);
            })
    }
    
    const checkDomainAvailability = (formattedText) => {
        let headerParams = {
            headers: {
                "Authorization": localStorage.getItem(AUTH)
            }
        };
        const params = {
            "domain_name": formattedText,
        }
        API.post(`blog/check-domain-availability`, params, headerParams)
            .then(res => {
                setCheckDomain(false);
                setIsDomainAvailable(res.data.data.is_available);
                // toastSuccess(res.data.message);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setCheckDomain(false);
                setIsDomainAvailable(false);
                toastError(error.message);
            })
    }
    
    
    return (
        <>
            <OBTopNav/>
            
            <div className="h-screen flex justify-center items-center -mt-16">
                
                <div className="max-w-5xl mx-auto my-auto">
                    
                    <div className={""}>
                        <div className="flex cursor-pointer">
                            <div className={"mr-4 mt-1 -ml-8"}
                                 onClick={() => {
                                     navigate(`/blogs`);
                                 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="w-5 h-5">
                                    <path fillRule="evenodd"
                                          d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                                          clipRule="evenodd"/>
                                </svg>
                            </div>
                            
                            <div>
                                <div className="text-xl font-bold">
                                    Create Blog
                                </div>
                                
                                <div className="mt-3 text-sm text-gray-600 pr-8">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna adivqua.
                                </div>
                            </div>
                        
                        </div>
                        
                        <hr className={"mt-6"}/>
                        
                        <div className="max-w-8xl mx-auto my-auto">
                            <div className="grid grid-cols-12 gap-4">
                                
                                <div className="col-span-5">
                                    <div className="mt-10 font-bold text-gray-600">
                                        {pageOne ? "Blog Details" : "SEO Questions"}
                                    </div>
                                    <div className="mt-3 text-sm text-gray-600 pr-8">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                        tempor
                                        incididunt ut labore et dolore magna adivqua.
                                    </div>
                                </div>
                                
                                <div className="col-span-7">
                                    <div className="bg-white rounded-lg border border-gray-200 shadow-lg p-6 mt-8">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mb-8">
                                                <label htmlFor="blogName"
                                                       className="text-xs uppercase font-bold text-gray-600 dark:text-white">Enter
                                                    your blog name</label>
                                                <input
                                                    className={`bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 ${formik.touched.blogName && formik.errors.blogName ? 'border-red-500' : ''}`}
                                                    id="blogName"
                                                    type="text"
                                                    placeholder="Healthy Nutrition"
                                                    value={blogName}
                                                    {...formik.getFieldProps('blogName')}
                                                    onChange={handleBlogNameInputChange}
                                                />
                                                {formik.touched.blogName && formik.errors.blogName && <div
                                                    className="text-red-500 text-xs mt-1 ml-1">{formik.errors.blogName}</div>}
                                            </div>
                                            
                                            <div className="mb-1">
                                                <label htmlFor="blogSlug"
                                                       className="text-xs uppercase font-bold text-gray-600 dark:text-white">Enter
                                                    your blog slug</label>
                                                <input
                                                    className={`bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 ${formik.touched.blogSlug && formik.errors.blogSlug ? 'border-red-500' : ''}`}
                                                    id="blogSlug"
                                                    type="text"
                                                    placeholder="healthy-nutrition"
                                                    value={blogSlug}
                                                    onChange={(e) => {
                                                        setBlogSlug(e.target.value);
                                                        checkDomainAvailability(e.target.value);
                                                    }}
                                                    {...formik.getFieldProps('blogSlug')}
                                                />
                                                {formik.touched.blogSlug && formik.errors.blogSlug && <div
                                                    className="text-red-500 text-xs mt-1 ml-1">{formik.errors.blogSlug}</div>}
                                            </div>
                                            
                                            <div className="pb-6">
                                                {checkDomain && <div className="flex float-right">
                                                    <div className="text-xs text-gray-600 font-medium m-2 pb-2">
                                                        Checking Domain Availability...
                                                    </div>
                                                </div>}
                                                
                                                {isDomainAvailable && !!blogSlug && !checkDomain &&
                                                    <div className="flex float-left">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                             className="w-4 h-4 text-green-700 mt-2 mr-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                                        </svg>
                                                        <div
                                                            className="text-xs text-green-700 font-medium mr-2 mt-2">Domain
                                                            Available
                                                        </div>
                                                    </div>}
                                                
                                                {!isDomainAvailable && !!blogSlug && !checkDomain &&
                                                    <div className="flex float-left">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                             className="w-4 h-4 text-red-700 mt-2 mr-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                                        </svg>
                                                        <div
                                                            className="text-xs text-red-700 font-medium mr-2 mt-2">Domain
                                                            Not Available
                                                        </div>
                                                    </div>}
                                            </div>
                                            
                                            <div className="mt-12 float-left">
                                                <button
                                                    type="submit"
                                                    className="bg-primary hover:bg-orange-600 text-white font-semibold text-sm py-2 px-8 w-36 rounded-lg"
                                                    disabled={!isDomainAvailable || isCreateBlogClicked || !blogName || !blogSlug}
                                                >
                                                    {isCreateBlogClicked ? "Creating..." : "Create Blog"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            
            </div>
        </>
    )
    
}
