import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AppContext from "../context/AppContext";
import API from "../api/api";
import {Toaster} from "react-hot-toast";
import {toastError, toastSuccess} from "../context/ToasterContext";
import Delight from "./../assets/img/delight.png";
import Fresh2024 from "./../assets/img/fresh-2024.png";
import ModernGrid from "./../assets/img/modern-grid.png";
import Classic from "./../assets/img/classic.png";
import mixpanel from "mixpanel-browser";

const gridConstant = [
    {id: 1, title: "Title", md: "Meta Description", author: "Author"},
    {id: 2, title: "Title", md: "Meta Description", author: "Author"},
    {id: 3, title: "Title", md: "Meta Description", author: "Author"},
    {id: 4, title: "Title", md: "Meta Description", author: "Author"},
]

const articleRowConstant = [
    {id: 2, row: 2},
    {id: 3, row: 3},
]

const themeOptions = [
    {id: 1, key: 'delight', name: 'Delight', img: Delight},
    {id: 2, key: 'fresh2024', name: 'Fresh 2024', img: Fresh2024},
    {id: 3, key: 'modernGrid', name: 'Modern Grid', img: ModernGrid},
    {id: 4, key: 'classic', name: 'Classic', img: Classic}
];


const ChooseTemplate = ({blog, isMigrate, isRouteChange, showSEO, publishSelectedStatus, fromSetting}) => {
    
    console.log("Selected Blog", blog);
    
    const navigate = useNavigate();
    let {token, account, store} = useContext(AppContext);
    
    const [selectedAccentColor, setSelectedAccentColor] = useState('#D03801');
    const [selectedImageInArticle, setSelectedImageInArticle] = useState('show-image-in-article');
    const [selectedDescInArticle, setSelectedDescInArticle] = useState('show-desc-in-article');
    const [articleRowCount, setArticleRowCount] = useState(2);
    const [loading, setLoading] = useState(false);
    const [chooseThemeDesign, setChooseThemeDesign] = useState('');
    
    const handleThemeDesignChange = (e) => {
        setChooseThemeDesign(e.target.value);
    };
    
    const handleAccentColorChange = (event) => {
        setSelectedAccentColor(event.target.value);
    };
    
    const handleImageInArticleRadioChange = (event) => {
        setSelectedImageInArticle(event.target.id);
    };
    
    const handleDescInArticleRadioChange = (event) => {
        setSelectedDescInArticle(event.target.id);
    };
    
    const handleArticleRowCount = (id) => {
        setArticleRowCount(id);
    }
    
    const saveArticlesAndTemplates = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            accent_color: selectedAccentColor,
            blog_design: chooseThemeDesign
            // show_image: selectedImageInArticle,
            // show_description: selectedDescInArticle,
            // article_per_row: articleRowCount
        }
        API.post(`blog/save-template/${account}/${store}/${blog.uuid}`, bodyParams, headerParams)
            .then(res => {
                setLoading(false);
                toastSuccess("Template and Article Saved");
                showSEO(true);
                mixpanel.track('Template Saved', {
                    "account": account,
                    "store": store,
                    "blog": blog.uuid,
                    "accent_color": selectedAccentColor,
                    "blog_design": chooseThemeDesign,
                });
                if (isRouteChange) {
                    localStorage.setItem("blogType", "new");
                    navigate(`/blogs/${blog.handle}/articles?migrate=true`, {
                        state: {
                            blog_id: blog.id,
                            blog_uuid: blog.uuid,
                            blog_title: blog.title,
                            blog_handle: blog.handle
                        }
                    });
                }
            })
            .catch(error => {
                setLoading(false);
                toastError(error.message);
            })
    }
    
    
    const handleMigrationBlog = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.post(`blog/migrate-articles/${account}/${store}/${blog.uuid}`, {}, headerParams)
            .then(res => {
            })
            .catch(error => {
                toastError(error.message);
            })
    }
    
    
    return (
        <div
            className="bg-white justify-center items-center border border-gray-200 shadow-lg p-4 rounded-xl mt-8">
            
            <div className="flex flex-row items-center justify-between my-3 pb-3">
                <span className="text-left text-lg ml-4 font-bold">Choose Template</span>
                
                {!fromSetting &&
                    <div onClick={() => publishSelectedStatus(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-8 h-8 mr-2 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                        </svg>
                    </div>
                }
            </div>
            
            <div className="pb-6">
                <hr className="border-t border-gray-200"/>
            </div>
            
            <div>
                <div className="w-full bg-white">
                    
                    <div className="grid grid-cols-1 gap-3 mb-3">
                        
                        <div>
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Choose Blog Theme</div>
                                
                                <div className="grid grid-cols-2 gap-4">
                                    {themeOptions.map((theme) => (
                                        <div key={theme.id}>
                                            <div
                                                className="flex flex-col justify-between items-center w-full ps-4 border border-gray-200 rounded-lg px-4 h-[360px]">
                                                <img className="mb-2 rounded-lg mt-4 mx-auto" src={theme.img}
                                                     alt={theme.name}/>
                                                <div
                                                    className="flex items-center justify-center text-center mt-auto pb-5">
                                                    <input
                                                        id={`theme-${theme.id}`}
                                                        type="radio"
                                                        value={theme.key}
                                                        name="choose-theme-design"
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                                        checked={chooseThemeDesign === theme.key}
                                                        onChange={handleThemeDesignChange}
                                                    />
                                                    <label
                                                        htmlFor={`theme-${theme.id}`}
                                                        className="ms-2 text-sm font-medium text-gray-900 px-2"
                                                    >
                                                        {theme.name}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Set Accent Color</div>
                                <div className="flex py-2">
                                    <div
                                        className="w-full text-sm px-1 pt-0.5 text-gray-900 dark:text-gray-300 uppercase font-bold">
                                        {selectedAccentColor}
                                    </div>
                                    
                                    <input
                                        id="accent-color"
                                        type="color"
                                        value={selectedAccentColor}
                                        className="w-16 h-6 mr-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                        onChange={handleAccentColorChange}
                                    />
                                </div>
                            </div>
                            
                            
                            {/*<div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Show Image In Article
                                </div>
                                <div className="flex">
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4">
                                        <input
                                            id="show-image-in-article"
                                            type="radio"
                                            value=""
                                            name="show-image-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedImageInArticle === 'show-image-in-article'}
                                            onChange={handleImageInArticleRadioChange}
                                        />
                                        <label htmlFor="show-image-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Show Image
                                        </label>
                                    </div>
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4 ml-3">
                                        <input
                                            id="dont-show-image-in-article"
                                            type="radio"
                                            value=""
                                            name="dont-show-image-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedImageInArticle === 'dont-show-image-in-article'}
                                            onChange={handleImageInArticleRadioChange}
                                        />
                                        <label htmlFor="dont-show-image-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Don't Show Image
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">
                                    Show Description In Article
                                </div>
                                <div className="flex">
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4">
                                        <input
                                            id="show-desc-in-article"
                                            type="radio"
                                            value=""
                                            name="show-desc-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedDescInArticle === 'show-desc-in-article'}
                                            onChange={handleDescInArticleRadioChange}
                                        />
                                        <label htmlFor="show-desc-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Show Description
                                        </label>
                                    </div>
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4 ml-3">
                                        <input
                                            id="dont-show-desc-in-article"
                                            type="radio"
                                            value=""
                                            name="dont-show-desc-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedDescInArticle === 'dont-show-desc-in-article'}
                                            onChange={handleDescInArticleRadioChange}
                                        />
                                        <label htmlFor="dont-show-desc-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Don't Show Description
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Article Row Count
                                </div>
                                <div className="mt-2 grid grid-cols-2 gap-3">
                                    {articleRowConstant.map((row) => (
                                        <div
                                            key={row.id}
                                            className={`text-gray-700 border border-gray-400 cursor-pointer w-full text-center font-bold rounded-lg text-sm px-6 py-2.5 ${
                                                articleRowCount === row.id ? 'bg-gray-500 text-white' : ''
                                            }`}
                                            onClick={() => handleArticleRowCount(row.id)}>
                                            {row.row} Rows
                                        </div>
                                    ))}
                                </div>
                            </div>*/}
                        </div>
                        
                        
                        {/*<div>
                            <div
                                className="bg-gray-100 rounded-lg font-medium p-4 m-2">
                                <div className="flex flex-row items-center justify-between mt-3">
                                    <span className="text-left text-sm ml-4 font-bold">Otaku Nerd Store</span>
                                    <button disabled
                                            style={{"background": `${selectedAccentColor}`}}
                                            className={`flex text-right text-white px-6 py-2 rounded-lg mr-4`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor" className="w-4 h-4 mt-0.5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
                                        </svg>
                                        <span className="ml-3 text-sm">Search</span>
                                    </button>
                                </div>
                                
                                <div className={"mb-4 mt-6 bg-gray-100 rounded-lg ml-4 mr-4"}>
                                    <div className={`grid grid-cols-${articleRowCount} gap-2`}>
                                        {gridConstant.map((key, value) => (
                                            <div className="bg-white rounded-xl mt-1">
                                                <div className="cursor-pointer">
                                                    {selectedImageInArticle === 'show-image-in-article' &&
                                                        <div className="flex-1">
                                                            <div className="w-full rounded-t-lg bg-gray-200 h-28"/>
                                                        </div>
                                                    }
                                                    <div
                                                        className="flex-1 p-4 my-auto ml-1 flex flex-col justify-between h-full">
                                                        <div className="font-bold mt-1 font-sm">{key.title}</div>
                                                        
                                                        <div className="flex flex-col justify-between h-full">
                                                            {selectedDescInArticle === 'show-desc-in-article' &&
                                                                <div className="text-xs text-gray-500 mt-1">
                                                                    {key.md}
                                                                </div>}
                                                            <div className="text-xs text-gray-500 mt-2 mb-1">
                                                                {key.author}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                
                <div className={"mx-auto text-center pt-4 pb-4"}>
                    <button type="button"
                            onClick={() => {
                                localStorage.setItem("blog_uuid", blog.uuid);
                                localStorage.setItem("blog_handle", blog.handle);
                                if (isMigrate) handleMigrationBlog();
                                saveArticlesAndTemplates();
                            }}
                            className="text-white w-1/4 font-bold mt-2 hover:bg-orange-800 bg-orange-600 rounded-lg text-sm px-6 py-2.5 mr-2">
                        {loading ? "Saving..." : "SAVE"}
                    </button>
                </div>
            </div>
            
            <Toaster/>
        
        </div>
    )
}

export default ChooseTemplate;
