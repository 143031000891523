import React, {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import API from "../api/api";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import AppContext from "../context/AppContext";
import {toastError, toastSuccess} from "../context/ToasterContext";
import toast, {Toaster} from 'react-hot-toast';
import mixpanel from 'mixpanel-browser';
import BlogEdit from "../assets/lotties/Blog_editing_2.json";
import Lottie from "react-lottie";


const Login = () => {
    
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [disableLoginButton, setDisableLoginButton] = useState(false);
    let {
        isAuthenticated,
        afterLogin,
        storeTokenData,
        storeUserData,
        storeAccountData,
        storeStoreData,
        triggerConfetti
    } = useContext(AppContext);
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: BlogEdit,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const loginPayload = {
            email, password
        }
        API.post(`signin`, loginPayload)
            .then(res => {
                const {data} = res.data;
                const {token, user, account} = data;
                afterLogin();
                storeTokenData(token);
                storeUserData(user);
                storeAccountData(account.uuid);
                storeStoreData(account.store_uuid);
                toastSuccess("Login Success");
                mixpanel.track('Logged In', {
                    'email': email,
                });
                if (account.is_store_connected) {
                    navigate(ROUTE_CONSTANT.BLOGS);
                } else {
                    navigate(ROUTE_CONSTANT.ONBOARDING_ACCOUNT);
                }
                setLoading(false);
                triggerConfetti();
            })
            .catch(error => {
                setDisableLoginButton(false);
                toastError(error.message);
                setLoading(false);
            })
    };
    
    
    return (
        <section className="h-full gradient-form bg-gradient-to-r from-white to-[#FEEFE2] md:h-full">
            <div className=" py-12 px-6 h-full">
                <div className="flex items-center justify-center flex-wrap h-full g-6 text-gray-800">
                    <div className="w-5/12">
                        <div className="block rounded-lg">
                            <div className="g-01">
                                <div className="px-4 md:px-0">
                                    <div className="md:p-12 md:mx-6">
                                        <div
                                            className="mx-auto justify-center place-content-center content-center place-items-center">
                                            <div
                                                className="mx-auto place-content-center content-center place-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="1.5"
                                                     stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"/>
                                                </svg>
                                                {/*<img className={"h-12"} src={BlogdLogoDark} alt={"Login"}/>*/}
                                            </div>
                                            <h4 className="text-xl font-semibold mt-3 mb-6 pb-1">Welcome back</h4>
                                            <h4 className="font-bold tracking-wide mb-6 pb-1 uppercase text-orange-500">
                                                <strong>Sign In</strong></h4>
                                        </div>
                                        
                                        {error &&
                                            <div
                                                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                                role="alert">
                                                {error}
                                            </div>}
                                        
                                        <form className="flex flex-col gap-4">
                                            <div>
                                                <div className="mb-2 block">
                                                    <label htmlFor="email"
                                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Your Email
                                                    </label>
                                                </div>
                                                <input type="email" id="email1"
                                                       className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="name@gmail.com"
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       required={true}/>
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <label htmlFor="password1"
                                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Your Password
                                                    </label>
                                                </div>
                                                <input type="password" id="password1"
                                                       className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="********"
                                                       onChange={(e) => setPassword(e.target.value)}
                                                       required={true}/>
                                            </div>
                                            
                                            <button type="button"
                                                    onClick={handleSubmit}
                                                    disabled={disableLoginButton}
                                                    className="text-white hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                                {!loading ? "Log In" : "Logging In..."}
                                            </button>
                                            
                                            <p className="text-bold text-center text-sm mt-2 underline cursor-pointer">
                                                <Link to={ROUTE_CONSTANT.CREATE_ACCOUNT}>
                                                    Don't have an account? Sign up here
                                                </Link>
                                            </p>
                                        </form>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="w-6/12 px-16 py-8 rounded-lg">
                        <Lottie
                            options={defaultOptions}
                            height={260}
                            width={260}
                        />
                        
                        <div className="flex mb-8 mt-12">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Connect Your Shopify Account</h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Create or Migrate Blogs</h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Generate Article Using <u
                                    className="text-orange-500">Blogd AI</u></h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Add Personalized Widgets</h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Login
