import React, {useState} from 'react';
import AccordionItem from "./AccordionItem";

const Accordion = () => {
    const [openId, setOpenId] = useState(null);
    
    const handleToggle = (id) => {
        setOpenId(openId === id ? null : id);
    };
    
    const accordionData = [
        {
            id: 1,
            title: 'Colored Mug Craze: How the 3D Maze Illusion Adds a Pop of Color to Your Day!',
            content: 'This is the content of accordion item 1.'
        },
        {
            id: 2,
            title: 'From the Campfire to Your Coffee Table',
            content: 'This is the content of accordion item 2.'
        },
        {
            id: 3,
            title: 'Enhance Your Phone\'s Style with 3D Patterns Snap Case for Samsung',
            content: 'This is the content of accordion item 3.'
        }
    ];
    
    const accordionData2 = [
        {
            id: 4,
            title: 'Colored Mug Craze: How the 3D Maze Illusion Adds a Pop of Color to Your Day!',
            content: 'This is the content of accordion item 1.'
        },
        {
            id: 5,
            title: 'From the Campfire to Your Coffee Table',
            content: 'This is the content of accordion item 2.'
        },
        {
            id: 6,
            title: 'Enhance Your Phone\'s Style with 3D Patterns Snap Case for Samsung',
            content: 'This is the content of accordion item 3.'
        }
    ];
    
    return (
        <div className="mx-auto mb-10 mt-6">
            <div className='bg-white p-4 rounded-lg shadow-md'>
            {accordionData.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    content={item.content}
                    isOpen={openId === item.id}
                    onClick={() => handleToggle(item.id)}
                    isLinked={false}
                />
            ))}
            </div>
            
            <div className="ml-2 mt-12 mb-4">
                <span className="text-lg font-bold">Interlinked Articles</span>
            </div>
            
            <div className="mx-auto">
            <div className='bg-white p-4 rounded-lg shadow-md'>
                {accordionData2.map((item, index) => (
                    <AccordionItem
                        key={item.id}
                        title={item.title}
                        content={item.content}
                        isOpen={openId === item.id}
                        onClick={() => handleToggle(item.id)}
                        isLinked={true}
                    />
                ))}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
