import {useContext, useEffect, useMemo, useRef, useState} from "react";
import ReactQuill, {Quill} from "react-quill";
import {Layout} from "antd";
import "react-quill/dist/quill.snow.css";
import "prismjs/themes/prism.css";
import katex from "katex";
import "katex/dist/katex.min.css";
import ImageResize from "quill-image-resize-module-react";
import WordFrequency from "../../components/quill/widgets/WordFrequency.js";
import SEOBestPractice from "../../components/quill/widgets/SEOBestPractice.js";
import AppContext from "../../context/AppContext.js";
import DynamicEditorSection from "../../components/quill/DynamicEditorSection.js";
import "../../components/quill/scss/custom-editor.scss";
import ProductCardWidget from "../../modules/Editor/steps/second/ProductCardWidget";
import ContentStructure from "../../components/quill/widgets/ContentStructure";
import ContentSummary from "../../components/quill/widgets/ContentSummary";

Quill.register("modules/imageResize", ImageResize);

const Editor = ({article, setArticle, articleContent, setArticleContent}) => {
    const [value, setValue] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const tabContainerRef = useRef(null);
    const {mode} = useContext(AppContext);
    const quillRefs = useRef([]);
    const [editorIndex, setEditorIndex] = useState(0);
    const [sectionRefs, setSectionRefs] = useState({});
    const [changeContent, setChangeContent] = useState(articleContent);
    
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    
    useEffect(() => {
        if (tabContainerRef.current) {
            const selectedTab = tabContainerRef.current.querySelector('.selected');
            if (selectedTab) {
                selectedTab.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
            }
        }
    }, [activeTab]);
    
    useEffect(() => {
    }, [changeContent]);
    
    const handleArticleContentChange = (newContent) => {
        setChangeContent(newContent);
    }
    
    const renderTabContent = () => {
        switch (activeTab) {
            case 1:
                return <ContentStructure
                    content={changeContent}
                    sectionRefs={sectionRefs}
                    setSectionRefs={setSectionRefs}/>
            case 2:
                return <ProductCardWidget
                    selectedText={selectedText}
                    index={index}
                    quillRefs={quillRefs}/>;
            case 3:
                    return <WordFrequency
                    content={changeContent}/>;
            case 4:
                    return <SEOBestPractice
                    content={articleContent}/>;
            case 5:
                return <div>Tab 5 Content</div>;
            case 6:
                return <div>Tab 6 Content</div>;
            case 7:
                return <div>Tab 7 Content</div>;
            case 8:
                return <div>Tab 8 Content</div>;
            case 9:
                return <div>Tab 9 Content</div>;
            case 10:
                return <div>Tab 10 Content</div>;
            default:
                return null;
        }
    };
    
    const {Sider, Content} = Layout;
    const [collapsed, setCollapsed] = useState(false);
    const [bubbleMenuClicked, setBubbleMenuClicked] = useState(1);
    const [index, setIndex] = useState(0);
    const [selectedText, setSelectedText] = useState("");
    
    const [allHelperWidgets, setAllHelperWidgets] = useState([
        {id: 1, helperWidgetName: "Content Structure", selected: false},
        {id: 2, helperWidgetName: "Widgets", selected: false},
        {id: 3, helperWidgetName: "Word Frequency", selected: false},
        {id: 4, helperWidgetName: "SEO", selected: false},
    ]);
    
    useEffect(() => {
        if (bubbleMenuClicked) {
            setActiveTab(bubbleMenuClicked);
        }
    }, [selectedText, editorIndex, bubbleMenuClicked, article]);
    
    
    return (
        <div className="w-full">
            <Layout>
                <Layout className="site-layout">
                    <Content style={{margin: '0 0', display: 'flex', flexDirection: 'row'}}>
                        <div className="child-row text-editor">
                            <div className="pl-4 pr-3 pt-4">
                                <div className="bg-white p-6 rounded-lg border border-gray-300">
                                    <div style={{maxHeight: "84vh", overflowY: 'auto'}}>
                                        <DynamicEditorSection
                                            quillRefs={quillRefs}
                                            selectedText={selectedText}
                                            setSelectedText={setSelectedText}
                                            setEditorIndex={setEditorIndex}
                                            setBubbleMenuClicked={setBubbleMenuClicked}
                                            bubbleMenuClicked={bubbleMenuClicked}
                                            article={article}
                                            articleContent={articleContent}
                                            setArticleContent={setArticleContent}
                                            setChangeContent={handleArticleContentChange}
                                            sectionRefs={sectionRefs}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
                
                <div>
                    <div className="pr-4 pt-4">
                        <ContentSummary content={changeContent}/>
                    </div>
                    
                    <Sider
                        className="customWidget border border-gray-300 rounded-lg mt-3 mr-4"
                        collapsible collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}>
                        <div className={"text-gray-700 p-4"}>
                            <div className="mb-4">
                                {
                                    <div className="overflow-x-auto mb-4 w-full">
                                        <style>
                                            {`
                                            .tab-container {
                                              display: flex;
                                              flex-wrap: nowrap;
                                              overflow-x: auto;
                                              scrollbar-width: none;
                                              -ms-overflow-style: none;
                                            }
                                            .tab-container::-webkit-scrollbar {
                                              display: none;
                                            }
                                        `}
                                        </style>
                                        <div className="flex items-center">
                                            <div className="tab-container" ref={tabContainerRef}>
                                                {allHelperWidgets.map((widget) => (
                                                    <div
                                                        key={widget.id}
                                                        className={`tab-item ${
                                                            activeTab === widget.id ? 'selected' : ''
                                                        }`}>
                                                        <button
                                                            className={`py-2 text-gray-600 px-4 ${
                                                                activeTab === widget.id
                                                                    ? 'font-bold border-b-amber-600 border-b-2'
                                                                    : 'border-b border-b-gray-300 font-medium'
                                                            }`}
                                                            onClick={() => handleTabClick(widget.id)}
                                                        >
                                                            {widget.helperWidgetName}
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>}
                                
                                <div className="">{renderTabContent()}</div>
                            </div>
                        
                        </div>
                    
                    </Sider>
                </div>
            
            </Layout>
        </div>
    );
};

export default Editor;
