import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import TopNav from "../components/layouts/TopNav";
import API from "../api/api";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import AppContext from "../context/AppContext";
import BlogdLogoDark from "../assets/img/blogd_dark_logo.png";
import ShopifyLogoWhiteBg from "../assets/img/shopify_logo_whitebg.png";
import CommonBg from "../components/layouts/CommonBg";
import OBTopNav from "../components/layouts/OBTopNav";
import Lottie from "react-lottie";
import {Toaster} from "react-hot-toast";
import mixpanel from "mixpanel-browser";
import BlogEdit from "../assets/lotties/Blog_editing_2.json";
import ShopifyStore from "../assets/lotties/Shopify_store.json";


export default function OnboardAccount() {
    
    const navigate = useNavigate();
    const {token, user, account, initialDataFetch} = useContext(AppContext);
    const [storeUrl, setStoreUrl] = useState("");
    const [pageOneVisible, setPageOneVisible] = useState(true);
    const [isDuplicateStore, setIsDuplicateStore] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: BlogEdit,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: ShopifyStore,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                // handleFetchBlog(account);
            } else {
                // navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    const connectToShopify = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            shop: storeUrl
        }
        API.post(`shopify/construct-shopify-url`, bodyParams, headerParams)
            .then(res => {
                window.location.href = res.data.data.location;
                mixpanel.track('Connect Shopify', {
                    'store_url': storeUrl,
                });
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log('Error: ', error);
                if (error.response.data.status === 'duplicate') {
                    setIsDuplicateStore(true);
                }
            })
    }
    
    return (
        <div>
            
            <OBTopNav/>
            
            <div className="h-screen flex justify-center bg-gradient-to-r from-white to-[#FEEFE2] items-center">
                <div
                    className="max-w-5xl mx-auto my-auto">
                    
                    <div className="-mt-44">
                        
                        {pageOneVisible && <>
                            <Lottie
                                options={defaultOptions}
                                height={160}
                                width={160}
                            />
                            
                            <div className="text-2xl text-center font-bold mt-8">
                                Create or Migrate Your Blogs from Shopify
                            </div>
                            
                            <div className="text-gray-600 text-center mt-4 mb-10">
                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod tempor <br/>
                                consectetur adipiscing edivt, sed do eiusmod tempor
                            </div>
                        </>}
                        
                        {pageOneVisible &&
                            <div
                                className="mx-auto text-center grid grid-cols-2 gap-y-4 gap-x-4 items-center">
                                
                                <div
                                    className="h-60 bg-white p-3 shadow-lg border border-gray-200 sm:rounded-lg cursor-pointer flex items-center justify-center">
                                    <div className="text-center"
                                         onClick={() => {
                                             setPageOneVisible(false);
                                             localStorage.setItem("blogType", "new");
                                             /*mixpanel.track('Logged In', {
                                                 'is_shopify': email,
                                             });*/
                                         }}>
                                        <div className="px-4 py-5 sm:px-6">
                                            <div className="flex">
                                                <div className={"mx-auto"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         strokeWidth="2" stroke="currentColor"
                                                         className="w-10 h-10 mb-3 text-blue-600">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M12 4.5v15m7.5-7.5h-15"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="mt-3 font-bold text-gray-600">
                                                Create New Blog
                                            </div>
                                            <div className="mt-3 text-sm text-gray-600">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor
                                                incididunt ut labore et dolore magna adivqua.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div
                                    className="h-60 bg-white p-3 shadow-lg border border-gray-200 sm:rounded-lg cursor-pointer flex items-center justify-center">
                                    <div className="text-center"
                                         onClick={() => {
                                             setPageOneVisible(false);
                                             localStorage.setItem("blogType", "migrate");
                                             // navigate(ROUTE_CONSTANT.BLOGS + "?type=new");
                                         }}>
                                        <div className="px-4 py-5 sm:px-6">
                                            <div className="flex">
                                                <div className={"mx-auto"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         strokeWidth="2" stroke="currentColor"
                                                         className="w-10 h-10 mb-3 text-orange-600">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="mt-3 font-bold text-gray-600">
                                                Migrate Existing Blog
                                            </div>
                                            <div className="mt-3 text-sm text-gray-600">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor
                                                incididunt ut labore et dolore magna adivqua.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>}
                        
                        {!pageOneVisible &&
                            
                            <div className={"-mt-36"}>
                                
                                <Lottie
                                    style={{"margin": "none"}}
                                    options={defaultOptions2}
                                    height={140}
                                    width={140}
                                />
                                
                                <div className="text-xl font-bold mt-6">
                                    Connect Store
                                </div>
                                
                                <div className="mt-3 text-sm text-gray-600 pr-8">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                    tempor
                                    incididunt ut labore et dolore magna adivqua.
                                </div>
                                
                                <hr className={"mt-6"}/>
                                
                                <div
                                    className="max-w-8xl mx-auto my-auto">
                                    <div className="grid grid-cols-12 gap-4">
                                        
                                        <div className="col-span-5">
                                            <div className="mt-10 font-bold text-gray-600">
                                                Shopify Store URL
                                            </div>
                                            <div className="mt-3 text-sm text-gray-600 pr-8">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                                tempor
                                                incididunt ut labore et dolore magna adivqua.
                                            </div>
                                        </div>
                                        
                                        <div className="col-span-7">
                                            <div
                                                className="bg-white rounded-lg border border-gray-200 shadow-lg p-6 mt-8">
                                                <label htmlFor="storeUrl"
                                                       className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                                                    Enter your shopify store url</label>
                                                <input
                                                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                                    id="storeUrl"
                                                    type="text"
                                                    placeholder="your-store.myshopify.com"
                                                    value={storeUrl}
                                                    onChange={(e) => {
                                                        setIsDuplicateStore(false);
                                                        setStoreUrl(e.target.value)
                                                    }}
                                                    required={true}/>
                                                <div className="mt-3 ml-1 text-xs text-gray-600">
                                                    {isDuplicateStore &&
                                                        <div className="text-red-600">Store already connected</div>}
                                                </div>
                                            </div>
                                            
                                            <div className="mt-6 float-right">
                                                <button
                                                    onClick={() => {
                                                        setPageOneVisible(true);
                                                    }}
                                                    className="bg-white border border-gray-400 text-gray-700 font-semibold text-sm py-2 px-8 w-36 rounded-lg mr-4">
                                                    Back
                                                </button>
                                                
                                                <button
                                                    onClick={() => {
                                                        connectToShopify();
                                                    }}
                                                    className={`bg-primary hover:bg-orange-600 text-white font-semibold text-sm py-2 px-8 w-36 rounded-lg ${loading ? 'cursor-not-allowed' : ''}`}>
                                                    {loading ? "Connecting..." : "Connect"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            
                            </div>
                            
                        }
                    
                    </div>
                    
                    <Toaster/>
                
                </div>
            </div>
        
        </div>
    )
}
