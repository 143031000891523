import {useContext, useEffect, useState} from 'react';
import {TEMPLATE_STRING, VISIBILITY_STRING, STYLE_STRING, STORE_UUID, PRODUCT_CARD_WIDGET} from "../../../../constants/app.constants";
import AppContext from "../../../../context/AppContext";
import API from "../../../../api/api";
import {useLocation} from "react-router";
import "../../../../components/quill/scss/custom-editor.scss";
import QuillEditorContext from "../../../../components/quill/context/QuillEditorContext";

const ProductCardWidget = ({selectedText, quillRefs, index}) => {

    const location = useLocation();
    
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    let {editorIndex, selectedEditorText, updateArticleHtml} = useContext(QuillEditorContext);
    
    const [visibilityValue, setVisibilityValue] = useState(VISIBILITY_STRING[0]);
    const [styleValue, setStyleValue] = useState(STYLE_STRING[0]);
    const [templateValue, setTemplateValue] = useState(TEMPLATE_STRING[0]);
    const [colorValue, setColorValue] = useState('#000000');
    const [selectedItem, setSelectedItem] = useState('');

    const [loading, setLoading] = useState(false);
    const [applyWidgetStatus, setApplyWidgetStatus] = useState(!(!!selectedText));
    const [allProducts, setAllProducts] = useState([]);
    const [allCollections, setAllCollections] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const {blog_uuid, article_uuid} = location.state;

    useEffect(() => {
        fetchAllProducts();
        // fetchAllCollections();
    }, []);

    const fetchAllProducts = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`shopify/products/${account}/${store_uuid}`, headerParams)
            .then(res => {
                if (res.data.data.products.length > 0) {
                    setAllItems(res.data.data.products);
                } else {
                    setAllItems([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            })
    }

    const fetchAllCollections = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`shopify/products/collections/${account}/${store_uuid}`, headerParams)
            .then(res => {
                if (res.data.data.collections.collects.length > 0) {
                    setAllItems(res.data.data.collections.collects);
                } else {
                    setAllItems([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            })
    }

    const createProductWidget = (payload) => {
        setLoading(true);
        setApplyWidgetStatus(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.post(`widgets/product_card/create/${account}/${store_uuid}/${blog_uuid}/${article_uuid}`, payload, headerParams)
            .then(res => {
                const {data} = res.data;
                setLoading(false);
                setApplyWidgetStatus(false);
                insertProductCard();
            })
            .catch(error => {
                setLoading(false);
                setApplyWidgetStatus(false);
            })
    }
    
    const insertProductCard = () => {
            if (selectedEditorText.length > 0) {
                quillRefs.current[editorIndex].editor.format('youtubeEmbedBlot', "id");
                const cursorPosition = quillRefs.current[editorIndex].editor.getSelection().index;
                quillRefs.current[editorIndex].editor.deleteText(cursorPosition, selectedEditorText.length);
                const content = `<span>${selectedEditorText}<span><span class="tooltiptext"><image class="product-center"><img src=${selectedItem.pics[0]} alt="Product Image" class="product-image"></image><div><div class="product-title-center">${selectedItem.title}</div><p class="product-price-center">$19.99</p><button class="product-button-center productWidget" onclick="window.open('${selectedItem.url}');">Buy Now</button></div></div></span>`;
                quillRefs.current[editorIndex].editor.insertEmbed(cursorPosition, 'my-blot', {
                    text: selectedEditorText,
                    id: "id",
                    content: `${content}`,
                });
            }
    }

    const handleProductChange = (event) => {
        let item = {...JSON.parse(event.target.value)};
        if (styleValue === STYLE_STRING[0]) {
            item.type = "single";
        } else if (styleValue === STYLE_STRING[1]) {
            item.type = "collection";
        } else if (styleValue === STYLE_STRING[2]) {
            item.type = "random";
        }
        setSelectedItem(item);
    };

    const handleColorChange = (event) => {
        setColorValue(event.target.value);
    };


    const changeStyleValue = (value) => {
        if (value === STYLE_STRING[0]) {
            fetchAllProducts();
        } else if (value === STYLE_STRING[1]) {
            fetchAllCollections();
        } else if (value === STYLE_STRING[2]) {
            setAllItems([]);
        }
        setStyleValue(value);
    }
    

    return (
        <>

            <div className="p-3">
                
                <div className="flex justify-between mb-6">
                    <div className="text-left">
                        <div className="text-lg font-semibold text-gray-700 ml-1">Add Product Widget</div>
                    </div>
                </div>

                <>
                    <label htmlFor="style"
                           className="block mb-2 mt-6 text-sm font-medium text-gray-900 dark:text-white ml-2">Style</label>
                    <div className="border border-gray-300 rounded-full p-1 inline-block">
                        <div className="flex">
                            <button
                                className={`${styleValue === STYLE_STRING[0] ? 'bg-primary text-white' : ''} font-semibold py-2 px-4 rounded-full mr-1`}
                                onClick={() => {
                                    changeStyleValue(STYLE_STRING[0]);
                                }}>
                                Fixed
                            </button>
                            <button
                                className={`${styleValue === STYLE_STRING[1] ? 'bg-primary text-white' : ''} font-semibold py-2 px-4 rounded-full ml-1`}
                                onClick={() => {
                                    changeStyleValue(STYLE_STRING[1]);
                                }}>
                                Dynamic
                            </button>
                            <button
                                className={`${styleValue === STYLE_STRING[2] ? 'bg-primary text-white' : ''} font-semibold py-2 px-4 rounded-full ml-1`}
                                onClick={() => {
                                    changeStyleValue(STYLE_STRING[2]);
                                }}>
                                Random
                            </button>
                        </div>
                    </div>
                </>

                {styleValue !== STYLE_STRING[2] &&
                    <>
                    <div className='flex flex-row items-center'>
                        <label htmlFor="product"
                               className="block mb-2 mt-6 text-sm font-medium text-gray-900 dark:text-white ml-2">
                            Select {styleValue === STYLE_STRING[0] ? 'Product' : 'Collection'}
                            </label>
                        {loading && (
                            <div className='ml-2 mb-2 mt-6'>
                                <svg className="animate-spin h-4 w-4 text-black" xmlns="http</div>://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                </div>
                        )}
                          </div>
                        <select
                            id="product"
                            disabled={loading}
                            onChange={handleProductChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            {loading ?
                                <option value="" className={"bg-gray-900"}>
                                    Please wait,
                                    fetching {styleValue === STYLE_STRING[0] ? 'products' : 'collections'}....
                                </option> :
                                <option value="">
                                    Choose {styleValue === STYLE_STRING[0] ? 'Product' : 'Collection'}
                                </option>
                            }
                            {allItems.map((item) => (
                                <option key={item.id} value={JSON.stringify(item)}>
                                    {styleValue === STYLE_STRING[0] ? item.title : item.collection_id}
                                </option>
                            ))}
                        </select>
                    </>}

                <>
                    <label htmlFor="color"
                           className="block mb-2 mt-6 text-sm font-medium text-gray-900 dark:text-white ml-2">Choose
                        Button Color</label>
                    <input
                        type="color"
                        id="color"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="John"
                        required
                        onChange={handleColorChange}
                    />
                </>

                <div className="mt-8 mb-2">
                    <div className="flex justify-between">
                        <button
                            disabled={applyWidgetStatus}
                            onClick={() => {
                                const payload = {
                                    widgetType: PRODUCT_CARD_WIDGET,
                                    visiblity: visibilityValue,
                                    style: styleValue,
                                    template: templateValue,
                                    button_color: colorValue,
                                    product_data: selectedItem
                                }
                                createProductWidget(payload);
                            }}
                            className={`font-semibold py-2 px-4 w-full rounded-lg ml-1 ${applyWidgetStatus ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary text-white'}`}>
                            Apply Widget
                        </button>
                    </div>
                </div>
                {!(!!selectedText) && <div className="mt-4 mb-1">
                    <p className='text-sm text-orange-400'>Please select a word to embed the product card </p>
                </div>}
            </div>

        </>
    );
}

export default ProductCardWidget;
