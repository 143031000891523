import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from "react-router";
import Sidebar from "../components/layouts/Sidebar";
import TopNav from "../components/layouts/TopNav";
import PageTitle from "../components/typography/PageTitle";
import AppContext from "../context/AppContext";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import Edit from "../assets/svg/edit.svg";
import Delete from "../assets/svg/delete.svg";
import DeployForm from "../components/shared/DeployForm";
import SecondaryTopNav from "../components/layouts/SecondaryTopNav";
import DomainSetting from "../components/shared/DomainSetting";
import ChooseTemplate from "./ChooseTemplate";


const MENU = [
    {id: 1, name: "Domain"},
    {id: 2, name: "Themes"},
    {id: 3, name: "Account Settings"},
]


export default function Settings() {
    
    const navigate = useNavigate();
    const location = useLocation();
    let {initialDataFetch, token, user, account} = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const {id} = useParams();
    const {blog_id, blog_uuid, blog_handle, blog_title, account_settings} = location.state;
    const [showCopied, setShowCopied] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const [tempArticleSelected, setTempArticleSelected] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(MENU[0]);
    const [isPublishSelected, setIsPublishSelected] = useState(false);
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                if (account_settings) {
                    setSelectedMenu(MENU[2]);
                }
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
        setSelectedBlog({
            id: blog_id,
            title: blog_title,
            handle: blog_handle,
            uuid: blog_uuid
        })
    }, [token, user]);
    
    const showSEO = (value) => {
    }
    
    const publishSelectedStatus = (status) => {
        setIsPublishSelected(status)
    }
    
    return (
        <div>
            
            <TopNav/>
            
            <SecondaryTopNav/>
            
            <div className="max-w-5xl px-6 mx-auto mb-8">
                
                <div className="ml-3">
                    <PageTitle>Settings</PageTitle>
                </div>
                
                
                <div
                    className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                    <ul className="flex flex-wrap -mb-px -ml-8">
                        {MENU.map((menu, index) => (
                            <div className="mr-2 cursor-pointer"
                                 key={index}
                                 onClick={() => {
                                     setSelectedMenu(menu);
                                 }}>
                                {selectedMenu.id === menu.id &&
                                    <div
                                        className="inline-block py-3 px-4 text-orange-600 border-b-2 border-orange-600 rounded-t-lg">
                                        {menu.name}
                                    </div>
                                }
                                {selectedMenu.id !== menu.id &&
                                    <div
                                        className="inline-block py-3 px-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300">
                                        {menu.name}
                                    </div>
                                }
                            </div>
                        ))
                        }
                    </ul>
                </div>
                
                {selectedMenu.id === 1 &&
                    <DomainSetting/>
                }
                
                {selectedMenu.id === 2 &&
                    <>
                        <ChooseTemplate
                            isMigrate={false}
                            blog={selectedBlog}
                            isRouteChange={true}
                            showSEO={showSEO}
                            publishSelectedStatus={publishSelectedStatus}
                            fromSetting={true}
                        />
                    </>
                }
                
                {selectedMenu.id === 3 &&
                    <>
                        <div
                            className="bg-white justify-center items-center border border-gray-200 shadow-lg p-4 rounded-xl mt-8">
                            <div className="text-left text-lg mt-2 mb-6 font-bold ml-4">Account Settings</div>
                            <div className="flex flex-col ml-4">
                                <div className="flex flex-row">
                                    <div className="flex flex-col w-1/2 mr-3">
                                        <label className="text-sm font-medium text-gray-700 ml-1">Name</label>
                                        <div className="mt-2">
                                            <input type="text"
                                                   className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                   placeholder="Enter name"/>
                                        </div>
                                    </div>
                                    
                                    <div className="flex flex-col w-1/2 ml-3">
                                        <label className="text-sm font-medium text-gray-700 ml-1">Email</label>
                                        <div className="mt-2">
                                            <input type="email"
                                                   className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                   placeholder="Enter Email"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <button
                                onClick={() => {
                                }}
                                className="inline-block bg-primary text-white font-bold py-2 px-8 rounded-lg mt-6 ml-4 mb-4">
                                Save
                            </button>
                        </div>
                    </>
                }
            
            </div>
        
        </div>
    )
}
