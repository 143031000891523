import React, {useContext, useEffect, useState} from 'react';
import {ROUTE_CONSTANT, STORE_UUID} from "../../constants/app.constants";
import API from "../../api/api";
import {toastError} from "../../context/ToasterContext";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import AppContext from "../../context/AppContext";
import _ from "lodash";

const CreateOwnArticleForm = ({ formData, setFormData }) => {
    const navigate = useNavigate();
    let { initialDataFetch, token, user, account, store, afterLogout } = useContext(AppContext);
    
    const [showAddCategoryField, setShowAddCategoryField] = useState(false);
    const [showAddAuthorField, setShowAddAuthorField] = useState(false);
    const [addNewCategory, setAddNewCategory] = useState('');
    const [addNewAuthor, setAddNewAuthor] = useState('');
    const [authors, setAuthors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isArticleNameEmpty, setIsArticleNameEmpty] = useState(false);
    const [isArticleSlugEmpty, setIsArticleSlugEmpty] = useState(false);
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                handleGetAuthors();
                handleGetCategories();
                fetchAllProducts();
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    const handleAuthorChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            articleAuthor: e.target.value
        }));
    };
    
    const handleCategoryChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            articleCategory: e.target.value
        }));
    };
    
    const handleGetAuthors = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`account/get-blog-authors/${account}`, headerParams)
            .then(res => {
                const { data } = res.data;
                setAuthors(data);
            })
            .catch(error => {
                console.log('Error: ', error.message);
            });
    };
    
    const handleGetCategories = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`account/get-blog-categories/${account}`, headerParams)
            .then(res => {
                const { data } = res.data;
                setCategories(data);
            })
            .catch(error => {
                console.log('Error: ', error.message);
            });
    };
    
    const handleAddNewCategory = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "blog_categories": addNewCategory,
        };
        API.post(`account/add-blog-categories/${account}`, params, headerParams)
            .then(res => {
                handleGetCategories();
                setAddNewCategory('');
                setShowAddCategoryField(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            });
    };
    
    const handleAddNewAuthor = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "blog_authors": addNewAuthor,
        };
        API.post(`account/add-blog-authors/${account}`, params, headerParams)
            .then(res => {
                handleGetAuthors();
                setAddNewAuthor('');
                setShowAddAuthorField(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            });
    };
    
    const fetchAllProducts = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`shopify/products/${account}/${store_uuid}`, headerParams)
            .then(res => {
                if (res.data.data.products.length > 0) {
                    setProducts(res.data.data.products);
                } else {
                    setProducts([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            });
    };
    
    return (
        <form>
            <div className="mb-8">
                <label htmlFor="articleName" className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                    Enter your article name
                </label>
                <input
                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                    id="articleName"
                    type="text"
                    placeholder="Healthy Nutrition"
                    value={formData.articleName}
                    onChange={(e) => {
                        setIsArticleNameEmpty(false);
                        const name = e.target.value;
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            articleName: name,
                            articleSlug: _.kebabCase(name)
                        }));
                    }}
                />
                {isArticleNameEmpty && <div className="text-red-500 text-xs mt-1 ml-1">Article Name is Required</div>}
            </div>
            
            <div className="mb-8">
                <label htmlFor="articleSlug" className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                    Enter your article slug
                </label>
                <input
                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                    id="articleSlug"
                    type="text"
                    disabled={true}
                    placeholder="healthy-nutrition"
                    value={formData.articleSlug}
                    onChange={(e) => {
                        setIsArticleSlugEmpty(false);
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            articleSlug: e.target.value
                        }));
                    }}
                />
                {isArticleSlugEmpty && <div className="text-red-500 text-xs mt-1 ml-1">Article Slug is Required</div>}
            </div>
            
            <div className="mb-8">
                <div className="flex">
                    <label htmlFor="articleSlug" className="text-xs uppercase font-bold text-gray-600 dark:text-white">
                        Select Author
                    </label>
                    <div
                        className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                        onClick={() => {
                            setShowAddAuthorField(true);
                        }}
                    >
                        <span className={""}>Add Author</span>
                    </div>
                </div>
                <select
                    id="author"
                    required={true}
                    onChange={handleAuthorChange}
                    value={formData.articleAuthor}
                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    <option value="">Select Author</option>
                    {authors.map((author, index) => (
                        <option key={index} value={author}>{author}</option>
                    ))}
                </select>
                {showAddAuthorField && (
                    <div className="mt-4">
                        <input
                            type="text"
                            value={addNewAuthor}
                            onChange={(e) => setAddNewAuthor(e.target.value)}
                            placeholder="New Author"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        />
                        <div className="flex justify-end space-x-2 mt-3">
                            <button
                                type="button"
                                onClick={handleAddNewAuthor}
                                className={`text-white hover:bg-orange-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ${addNewAuthor.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={addNewAuthor.trim() === ''}
                            >
                                Add Author
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setAddNewAuthor('');
                                    setShowAddAuthorField(false);
                                }}
                                className="text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-1.5"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
            
            <div className="mb-8">
                <div className="flex">
                    <label htmlFor="articleCategory" className="text-xs uppercase font-bold text-gray-600 dark:text-white">
                        Select Category
                    </label>
                    <div
                        className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                        onClick={() => {
                            setShowAddCategoryField(true);
                        }}
                    >
                        <span className={""}>Add Category</span>
                    </div>
                </div>
                <select
                    id="category"
                    required={true}
                    onChange={handleCategoryChange}
                    value={formData.articleCategory}
                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    <option value="">Select Category</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
                </select>
                {showAddCategoryField && (
                    <div className="mt-4">
                        <input
                            type="text"
                            value={addNewCategory}
                            onChange={(e) => setAddNewCategory(e.target.value)}
                            placeholder="New Category"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        />
                        <div className="flex justify-end space-x-2 mt-3">
                            <button
                                type="button"
                                onClick={handleAddNewCategory}
                                className={`text-white hover:bg-orange-500 bg-primary font-medium rounded-lg text-sm px-3 py-1.5 ${addNewCategory.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={addNewCategory.trim() === ''}
                            >
                                Add Category
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setAddNewCategory('');
                                    setShowAddCategoryField(false);
                                }}
                                className="text-white bg-gray-600 hover:bg-gray-700 font-medium rounded-lg text-sm px-3 py-1.5"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
        
        </form>
    );
};

export default CreateOwnArticleForm;
