import React, {useState} from 'react';
import AccordionSubItem from "./AccordionSubItem";

const AccordionItem = ({title, content, isOpen, onClick, isLinked}) => {
    return (
        <div className="py-1.5">
            <div
                className="w-full flex items-center px-4 py-3 text-left border border-gray-300 rounded-lg cursor-pointer"
            >
                <span className="font-medium text-sm">{title}</span>
                <span
                    className="ml-3 bg-orange-100 text-orange-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                    10 Keywords
                </span>
                
                <span
                    className="ml bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                    6 Unique Links
                </span>
                
                <div className="flex ml-auto">
                    
                    {isLinked ?
                        <button type="button"
                                className="text-orange-600 hover:text-white border border-orange-600 hover:bg-orange-700 font-medium rounded text-xs px-5 py-1 mr-3 text-center">
                            Edit
                        </button> :
                        <button type="button"
                                className="text-orange-600 hover:text-white border border-orange-600 hover:bg-orange-700 font-medium rounded text-xs px-5 py-1 mr-3 text-center">
                            Regenerate
                        </button>}
                    
                    <div className="mt-0.5"
                         onClick={onClick}>
                        {isOpen ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth="1.5"
                                 stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="m4.5 15.75 7.5-7.5 7.5 7.5"/>
                            </svg>
                        ) : (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5"
                                     stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
                                </svg>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {isOpen && (
                <div
                    className="ml-4 mt-4 w-full items-center text-left border border-gray-300 rounded-lg cursor-pointer bg-gray-100">
                    <AccordionSubItem isLinked={isLinked}/>
                    <AccordionSubItem isLinked={isLinked}/>
                    <AccordionSubItem isLinked={isLinked}/>
                    <AccordionSubItem isLinked={isLinked}/>
                    <AccordionSubItem isLinked={isLinked}/>
                    <AccordionSubItem isLinked={isLinked}/>
                    <AccordionSubItem isLinked={isLinked}/>
                    <AccordionSubItem isLinked={isLinked}/>
                </div>
            )}
        </div>
    );
};

export default AccordionItem;
