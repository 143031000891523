import React, {useContext, useEffect, useState} from 'react';
import {ROUTE_CONSTANT, STORE_UUID} from "../../constants/app.constants";
import API from "../../api/api";
import {toastError} from "../../context/ToasterContext";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import AppContext from "../../context/AppContext";
import _ from "lodash";

const KEYWORDS = [
    {id: 1, keyword: "Keyword 1", difficulty: "Medium", opportunity: "🔥"},
    {id: 2, keyword: "Keyword 2", difficulty: "High", opportunity: ""},
    {id: 3, keyword: "Keyword 3", difficulty: "Low", opportunity: "🔥"},
    {id: 4, keyword: "Keyword 4", difficulty: "Medium", opportunity: "🔥"},
    {id: 5, keyword: "Keyword 5", difficulty: "Low", opportunity: "🔥"},
    {id: 6, keyword: "Keyword 6", difficulty: "High", opportunity: ""},
    {id: 7, keyword: "Keyword 7", difficulty: "Low", opportunity: "🔥"},
    {id: 8, keyword: "Keyword 8", difficulty: "High", opportunity: ""},
    {id: 9, keyword: "Keyword 9", difficulty: "High", opportunity: ""},
    {id: 10, keyword: "Keyword 10", difficulty: "Medium", opportunity: "🔥"}
];

const StepTwoKeywords = ({aiSettingsFormData, setAISettingsFormData, isKeywordEmpty, setIsKeywordEmpty}) => {
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    
    const [allKeywords, setAllKeywords] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const handleCheckboxChange = (keyword) => {
        setSelectedKeywords((prevSelected) => {
            let updatedSelected;
            if (prevSelected.includes(keyword)) {
                updatedSelected = prevSelected.filter(item => item !== keyword);
            } else {
                updatedSelected = [...prevSelected, keyword];
            }
            
            setAISettingsFormData(prevFormData => ({
                ...prevFormData,
                keywords: updatedSelected.join(', ')
            }));
            
            return updatedSelected;
        });
    };
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                getKeywordSuggestions();
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    useEffect(() => {
        if (aiSettingsFormData.keywords) {
            const existingKeywords = aiSettingsFormData.keywords.split(', ').map(keyword => keyword.trim());
            setSelectedKeywords(existingKeywords);
        }
    }, [aiSettingsFormData.keywords]);
    
    const getKeywordSuggestions = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.post(`article-generator/get-keyword-suggestions`, {}, headerParams)
            .then(res => {
                const {data} = res.data;
                setAllKeywords(data);
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            });
    };
    
    return (
        <form>
            <div className="">
                <div className="mb-4 uppercase text-sm font-bold text-gray-900">
                    Keyword Ideas from <span className="text-orange-600">Blogd AI</span>
                </div>
                
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox"
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg hidden"/>
                                    <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Keywords
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Difficulty
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opportunity
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Volume
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {allKeywords.map((keyword, index) => (
                            <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                <td className="w-4 p-4">
                                    <div className="flex items-center">
                                        <input
                                            id={`checkbox-${index}`}
                                            type="checkbox"
                                            checked={selectedKeywords.includes(keyword.keyword)}
                                            onChange={() => {
                                                setIsKeywordEmpty(false);
                                                handleCheckboxChange(keyword.keyword)
                                            }}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg"
                                        />
                                        <label htmlFor={`checkbox-${index}`} className="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {keyword.keyword}
                                </th>
                                <td className="px-6 py-4 capitalize">
                                    {keyword.keyword_difficulty === 'easy' && <div className="text-green-500">{keyword.keyword_difficulty}</div>}
                                    {keyword.keyword_difficulty === 'medium' && <div className="text-gray-500">{keyword.keyword_difficulty}</div>}
                                    {keyword.keyword_difficulty === 'hard' && <div className="text-red-500">{keyword.keyword_difficulty}</div>}
                                </td>
                                <td className="px-6 py-4 capitalize">
                                    {keyword.opportunity === 'low' && <div className="text-green-500">{keyword.opportunity}</div>}
                                    {keyword.opportunity === 'medium' && <div className="text-gray-500">{keyword.opportunity}</div>}
                                    {keyword.opportunity === 'high' && <div className="text-red-500">{keyword.opportunity}</div>}
                                </td>
                                <td className="px-6 py-4">
                                    {keyword.volume}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    
                    {isKeywordEmpty && <div className="text-red-500 text-xs mt-1.5 ml-1">Keyword not selected</div>}
                </div>
            
            </div>
        
        </form>
    );
};

export default StepTwoKeywords;
