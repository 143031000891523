import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../components/typography/PageTitle";
import Sidebar from "../components/layouts/Sidebar";
import ConnectShopifyStore from "../components/shared/ConnectShopifyStore";
import TopNav from "../components/layouts/TopNav";
import TopNavSecondary from "../components/layouts/TopNavSecondary";
import ArticlePlaceHolder from "../assets/img/ArticlePlaceHolder.png";
import Calendar from "../assets/svg/calendar.svg";
import Delete from "../assets/svg/delete.svg";
import Edit from "../assets/svg/edit.svg";
import Share from "../assets/svg/share.svg";
import Eye from "../assets/svg/eye.svg";
import { ROUTE_CONSTANT, STORE_UUID } from "../constants/app.constants";
import { useNavigate } from "react-router-dom";
import API from "../api/api";
import AppContext from "../context/AppContext";
import { useLocation, useParams } from "react-router";
import moment from "moment";
import AppModal from "../components/shared/AppModal";
import DeployForm from "../components/shared/DeployForm";
import { cssContent } from "../constants/style.constants";
import AppModalShort from "../components/shared/AppModalShort";
import SecondaryTopNav from "../components/layouts/SecondaryTopNav";
import SEOReportView from "../components/layouts/SEOReportView";
import EmptyImage from "../assets/img/empty-image.png";
import Lottie from "react-lottie";
import VerifyLottie from "../assets/lotties/payment-verify.json";
import { toastError, toastSuccess } from "../context/ToasterContext";
import AppModalLong from "../components/shared/AppModalLong";
import mixpanel from "mixpanel-browser";
// import Editor from "./Editor/Editor";
import ContentRepurpose from "./ContentRepurpose.js";

export default function Articles() {
  const [allArticleSections, setAllArticleSections] = useState([
    { id: 1, name: "All", selected: false },
    {
      id: 2,
      name: "Posted",
      selected: false,
    },
    { id: 3, name: "Scheduled", selected: false },
    { id: 4, name: "Drafts", selected: false },
  ]);

  const navigate = useNavigate();
  const location = useLocation();
  let { initialDataFetch, token, user, account, store, triggerConfetti } =
    useContext(AppContext);
  const [allArticles, setAllArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();
  const { blog_id, blog_uuid, blog_title, blog_handle } = location.state;
  const [articleObject, setArticleObject] = useState({});

  const [showAddCategoryField, setShowAddCategoryField] = useState(false);
  const [isCreateArticleDetailModalOpen, setIsCreateArticleDetailModalOpen] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deployStatus, setDeployStatus] = useState("");
  const [deployMessage, setDeployMessage] = useState("");

  const [activeTab, setActiveTab] = useState(1);
  const [showSEOReport, setShowSEOReport] = useState(false);
  const [count, setCount] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [fetchCalled, setFetchCalled] = useState(false);
  const [isPublishView, setIsPublishView] = useState("");
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [selectedArticleUUID, setSelectedArticleUUID] = useState("");
  const [html, setHtml] = useState("");
  const [htmlLoader, setHtmlLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [filteredArticles, setFilteredArticles] = useState([]);

  const [openContentRepurpose, setOpenContentRepurpose] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: VerifyLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleScheduleOpenModal = (article) => {
    setSelectedArticle(article.title);
    setSelectedArticleUUID(article.uuid);
    setIsScheduleModalOpen(true);
  };

  const handleScheduleCloseModal = () => {
    setIsScheduleModalOpen(false);
  };

  const handleContentRepurpose = (article) => {
    setOpenContentRepurpose(!openContentRepurpose);
    setSelectedArticle(article);
    console.log('Article', article)
};


  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  //change the filtered articles based on the tab selected
  useEffect(() => {
    if (activeTab === 1) {
      setFilteredArticles(allArticles);
    } else if (activeTab === 2) {
      const filtered = allArticles.filter((article) => {
        return article.status === "published";
      });
      setFilteredArticles(filtered);
    } else if (activeTab === 3) {
      const filtered = allArticles.filter((article) => {
        return article.status === "scheduled";
      });
      setFilteredArticles(filtered);
    } else if (activeTab === 4) {
      const filtered = allArticles.filter((article) => {
        return article.status === "draft";
      });
      setFilteredArticles(filtered);
    }
  }, [activeTab, allArticles]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (initialDataFetch) {
      if (!!token && !!user && !!store) {
        if (!store) {
          setAllArticles([]);
        } else if (!fetchCalled) {
          setFetchCalled(true);
          handleFetchArticles();
        }
      } else {
        navigate(ROUTE_CONSTANT.LOGIN);
      }
    }
  }, [initialDataFetch, token, user, store, fetchCalled]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const publishParam = searchParams.get("migrate");
    setIsPublishView(publishParam);
    if (initialDataFetch) {
      if (publishParam === "true") {
        const id = setInterval(() => {
          if (
            deployStatus === "published" ||
            deployStatus === "Failed" ||
            deployStatus === "Error"
          ) {
            clearInterval(intervalId);
            return;
          }
          getDeployStatus();
          setCount((prevCount) => prevCount + 1);
          if (count === 4) {
            clearInterval(intervalId);
          }
        }, 1000);
        setIntervalId(id);
        return () => {
          clearInterval(id);
        };
      }
    }
  }, [initialDataFetch, count, deployStatus]);

  const publishBlog = () => {
    let headerParams = {
        headers: {
            "Authorization": token
        }
    };
    const params = {
        "domain_name": blog_handle,
    }
    API.post(`blog/publish/${account}/${store}/${blog_uuid}`, params, headerParams)
        .then(res => {
            const data = res.data;
            console.log("PUBLISH BLOG RESPONSE: ", data);
                mixpanel.track('Article Published', {
                    "account": account,
                    "blog_uuid": blog_uuid,
                    "store": store,
                    "domain_name": blog_handle
                });
                setDeployStatus('published');  
              })
        .catch(error => {
            console.log('Error: ', error.message);
            setDeployStatus('Failed');  
            toastError(error.message);
        })
}


//call the API to publish the blog if isPublishView is true
useEffect(() => {
    if (isPublishView === "true") {
        publishBlog();
    }
}, [isPublishView]);


  const handleFetchArticle = (article_uuid) => {
    setHtmlLoader(true);
    let headerParams = {
      headers: {
        Authorization: token,
      },
    };
    API.get(
      `articles/get-article/${account}/${store}/${blog_uuid}/${article_uuid}`,
      headerParams
    )
      .then((res) => {
        if (!!res.data.data) {
          const { article_html } = res.data.data;
          //handle empty article_html
          article_html ? setHtml(article_html) : setHtml(`<p className="text-md text-gray-500">No Content to preview</p>`);
          setHtmlLoader(false);
        } else {
          setHtmlLoader(false);
        }
      })
      .catch((error) => {
        setHtmlLoader(false);
        setError(error.message);
      });
  };

  const handleFetchArticles = () => {
    setLoading(true);
    let headerParams = {
      headers: {
        Authorization: token,
      },
    };
    API.get(
      `articles/list-articles/${account}/${store}/${blog_uuid}`,
      headerParams
    )
      .then((res) => {
        if (res.data.data.length > 0) {
          setAllArticles(res.data.data);
          setLoading(false);
        } else {
          setAllArticles([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  const getDeployStatus = () => {
    let headerParams = {
      headers: {
        Authorization: token,
      },
    };
    API.get(`blog/get-deploy-status/${blog_uuid}`, headerParams)
      .then((res) => {
        const { message, displayMessage } = res.data;
        setDeployStatus(message);
        setDeployMessage(displayMessage);
        if (message === "published") {
          navigate(`/blogs/${blog_handle}/articles`, {
            state: {
              blog_id: blog_id,
              blog_uuid: blog_uuid,
              blog_title: blog_title,
              blog_handle: blog_handle,
            },
          });
          toastSuccess("Deploy Completed Successfully");
          triggerConfetti();
        } else if (message === "failed" || message === "error") {
          toastError("Deploy Failed, Sorry! Try Again");
          navigate(`/blogs/${blog_handle}/articles`, {
            state: {
              blog_id: blog_id,
              blog_uuid: blog_uuid,
              blog_title: blog_title,
              blog_handle: blog_handle,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  };

  // search functionality
  useEffect(() => {
    const filtered = allArticles.filter((article) => {
      const title = !!article.title ? article.title.toLowerCase() : "";
      return title.includes(searchInput.toLowerCase());
    });
    setFilteredArticles(filtered);
  }, [searchInput, allArticles]);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setActiveTab(1);
  };

  const showSEOReportStatus = (status) => {
    setShowSEOReport(status);
  };

  const [selectedDate, setSelectedDate] = useState("");
  const [loadSchedule, setLoadSchedule] = useState(false);

  const handleScheduleArticle = () => {   
    setLoadSchedule(true);
    let headerParams = {
      headers: {
        Authorization: token,
      },
    };
    const params = {
      schedule_date: selectedDate,
    };
    API.post(
      `articles/schedule-article/${account}/${store}/${blog_uuid}/${selectedArticleUUID}`,
      params,
      headerParams
    )
      .then((res) => {
        mixpanel.track("Article Scheduled", {
          account: account,
          blog_uuid: blog_uuid,
          store: store,
          domain_name: blog_handle,
        });
        handleScheduleCloseModal();
        toastSuccess("Scheduled Successfully!");
        setLoadSchedule(false);
      })
      .catch((error) => {
        console.log("Error: ", error.message);
        toastError(error.message);
        setLoadSchedule(false);
      });
  };

  // article preview on new tab
  const handleArticlePreview = (article) => {
    window.open(`${article.preview_url}`, "_blank");
  };

  return (
    <div>
      <TopNav />

      <SecondaryTopNav />

      {!loading && (
        <>
          {allArticles.length > 0 && (
            <>
              <>
                {!showSEOReport && (
                  <div className="max-w-7xl px-6 mx-auto mb-8">
                    <div className="flex flex-row items-center justify-between my-3 pb-3">
                      <span className="text-left text-lg ml-4 font-bold">
                        <h3 className={"my-6 font-bold text-gray-700"}>
                          Your Articles
                        </h3>
                      </span>

                      <div>
                        {/*<button
                                    onClick={() => {
                                        setShowSEOReport(true);
                                    }}
                                    className="inline-block mr-2 border border-orange-600 text-sm text-orange-600 font-bold py-2 px-8 rounded-lg">
                                    Show Report
                                </button>*/}

                        <button
                          onClick={() => {
                            navigate(ROUTE_CONSTANT.CREATE_ARTICLE, {
                              state: {
                                blog_id,
                                blog_uuid,
                                blog_title,
                                blog_handle,
                              },
                            });
                          }}
                          className="inline-block bg-primary text-sm text-white font-bold py-2 px-8 rounded-lg"
                        >
                          Create Article
                        </button>
                      </div>
                    </div>

                    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                      <ul className="flex flex-wrap -mb-px -ml-8">
                        {allArticleSections.map((section) => (
                          <div
                            key={section.id}
                            className={`tab-item ${
                              activeTab === section.id ? "selected" : ""
                            }`}
                          >
                            <button
                              className={`inline-block py-3 px-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${
                                activeTab === section.id
                                  ? "font-bold text-amber-600 border-b-amber-600 border-b-2 hover:text-amber-600 hover:border-b-2 hover:border-b-amber-600"
                                  : "font-medium"
                              }`}
                              onClick={() => handleTabClick(section.id)}
                            >
                              {section.name}
                            </button>
                          </div>
                        ))}

                        <div className="mr-2 cursor-pointer ml-auto -mt-2">
                          <form>
                            <div className="relative mt-4">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                  className="w-4 h-4 -mt-2 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                  />
                                </svg>
                              </div>
                              <input
                                type="search"
                                id="default-search"
                                className="w-full -mt-6 p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Search All Articles..."
                                onChange={handleSearch}
                                value={searchInput}
                                required
                              />
                            </div>
                          </form>
                        </div>
                      </ul>
                    </div>

                    <div className="mt-6">
                      <div className="relative overflow-x-auto border border-gray-300 sm:rounded-lg">
                        {!loading && error.length === 0 && (
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 leading-6">
                            <thead className="text-xs text-gray-700 h-16 uppercase bg-gray-50 border-b">
                              <tr>
                                <th scope="col" className="px-6 py-3 w-2/6">
                                  Article Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Author
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Status
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                  SEO
                                </th> */}
                                <th scope="col" className="px-6 py-3">
                                  Created
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Last Updated
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* handle empty array */}
                              {filteredArticles.length < 1 && (
                                <tr className="bg-white mb-4">
                                  <td colSpan="6" className="text-center text-[#E35C37]">
                                    No Articles Matched This Criterea
                                  </td>
                                </tr>
                              )}
                              {filteredArticles.length > 0 &&
                              filteredArticles?.map((article, index) => (
                                <tr
                                  key={index}
                                  className="odd:bg-white even:bg-gray-50 border-b"
                                >
                                  <th
                                    scope="row"
                                    onClick={() => {
                                      setArticleObject(article);
                                      handleFetchArticle(article.uuid);
                                      handleOpenModal();
                                    }}
                                    className="px-6 py-4 font-normal text-gray-900 dark:text-white break-words whitespace-normal w-2/6"
                                  >
                                    {article.title}
                                  </th>
                                  <td className="px-6 py-4 capitalize">
                                    {article.author}
                                  </td>
                                  <td className="px-6 py-4">
                                    {article.status === "draft" && (
                                      <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg border border-gray-200 capitalize">
                                        {article.status}
                                      </span>
                                    )}
                                    {article.status === "published" && (
                                      <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg border border-green-200 capitalize">
                                        Published
                                      </span>
                                    )}
                                    {article.status === "scheduled" && (
                                      <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg border border-blue-200 capitalize">
                                        Scheduled
                                      </span>
                                    )}
                                    {article.status === "unpublished" && (
                                      <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg border border-red-200 capitalize">
                                        Unpublished
                                      </span>
                                    )}
                                    {article.status === "" && (
                                      <span className="bg-yellow-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg border border-gray-200 capitalize">
                                        Yet to be Published
                                      </span>
                                    )}
                                  </td>
                                  {/* <td className="px-6 py-4 capitalize">
                                    {article.is_seo_optimized ? (
                                      <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg border border-green-200">
                                        Optimized
                                      </span>
                                    ) : (
                                      <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg border border-gray-200">
                                        Not Optimized
                                      </span>
                                    )}
                                  </td> */}
                                  <td className="px-6 py-4">
                                    {moment(article.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                    <br />
                                    {moment(article.created_at).format(
                                      "h:mm A"
                                    )}
                                  </td>
                                  <td className="px-6 py-4">
                                    {moment(article.updated_at).format(
                                      "DD MMM YYYY"
                                    )}
                                    <br />
                                    {moment(article.updated_at).format(
                                      "h:mm A"
                                    )}
                                  </td>
                                  <td className="flex px-6 py-4 my-auto">
                                    <img
                                      src={Edit}
                                      onClick={() => {
                                        navigate(
                                          `/blogs/${id}/articles/${article.handle}/editor`,
                                          {
                                            state: {
                                              article_id: article.id,
                                              article_uuid: article.uuid,
                                              blog_id,
                                              blog_uuid,
                                              blog_handle,
                                              blog_title,
                                            },
                                          }
                                        );
                                      }}
                                      className="cursor-pointer m-auto mx-2 w-6"
                                      alt="Edit"
                                    />
                                    <img
                                      src={Calendar}
                                      onClick={() =>
                                        handleScheduleOpenModal(article)
                                      }
                                      className="mx-2 w-6"
                                      alt="Calender"
                                    />
                                     <img
                                      src={Share}
                                      onClick={() =>
                                        handleContentRepurpose(article)
                                      }
                                      className="mx-2 w-6"
                                      alt="share"
                                    />

                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}

                        {!loading && error.length > 0 && (
                          <div className="flex justify-center items-center mt-44">
                            <div className="text-center">
                              <h1 className="text-2xl font-bold">Oops...</h1>
                              <p className="text-gray-500">{error}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {isModalOpen && (
                      <AppModalLong title={""}>
                        <div className="">
                          <div className="flex justify-between py-3 px-4 text-sm text-bold">
                            <div className="text-left">
                              <h1 className="text-xl font-bold">
                                {articleObject.title}
                              </h1>
                            </div>

                            <div onClick={handleCloseModal}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                          </div>

                          <div className="pb-1">
                            <div className="mt-3">
                              <hr />
                            </div>

                            <div>
                              {!htmlLoader ? (
                                <>
                                  <h1 className="text-3xl font-bold text-gray-800 dark:text-white ml-4 pt-6 mb-2">
                                    {articleObject.title}
                                  </h1>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `
                                                <style>
                                                ${cssContent}
                                                </style>
                                                <div class="outer-body">
                                                ${html}
                                                </div>
                                                `,
                                    }}
                                  />
                                </>
                              ) : (
                                <div className="flex justify-center items-center">
                                  <Lottie
                                    options={defaultOptions}
                                    height={200}
                                    width={200}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </AppModalLong>
                    )}

                    {/* {isPublishView === "true" && (
                      <>
                        <div className="">
                          <div className="fixed bottom-12 inset-x-0 text-center">
                            <div className="mt-12 flex mx-auto text-center justify-center">
                              <div className="bg-white border border-gray-200 shadow-lg w-2/4 py-6 px-8 rounded-full">
                                <div className="flex justify-between mb-2">
                                  <span className="font-medium text-sm text-blue-700 dark:text-white">
                                    {!!deployStatus
                                      ? "Deploy Status: " + deployStatus
                                      : "Deploying"}
                                    ...
                                  </span>
                                  <span className="text-sm font-medium text-blue-700 dark:text-white">
                                    85%
                                  </span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                  <div
                                    className="bg-blue-600 h-2.5 rounded-full"
                                    style={{ width: "85%" }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}

{isPublishView === "true" && (
  <>
    <div className="">
      <div className="fixed bottom-12 inset-x-0 text-center">
        <div className="mt-12 flex mx-auto text-center justify-center">
          <div className="bg-white border border-gray-200 shadow-lg w-2/4 py-6 px-8 rounded-full">
            <div className="flex justify-between mb-2">
              <span className="font-medium text-sm text-blue-700 dark:text-white">
                {deployStatus ? "Deploy Status: " + deployMessage : "Deploying"}...
              </span>
              <span className="text-sm font-medium text-blue-700 dark:text-white">
                {deployStatus === "fetching_articles" && "20%"}
                {deployStatus === "deploying_articles" && "50%"}
                {deployStatus === "domain_pointing" && "70%"}
                {deployStatus === "deployed" && "90%"}
                {deployStatus === "published" && "100%"}
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-in-out"
                style={{
                  width:
                    deployStatus === "fetching_articles"
                      ? "20%"
                      : deployStatus === "deploying_articles"
                      ? "50%"
                      : deployStatus === "domain_pointing"
                      ? "70%"
                      : deployStatus === "deployed"
                      ? "90%"
                      : deployStatus === "published"
                      ? "100%"
                      : "0%"
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)}


                    {isScheduleModalOpen && (
                      <AppModalShort title={""}>
                        <div className="">
                          <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                              <h2 className="text-xl font-bold">
                                {selectedArticle}
                              </h2>
                            </div>

                            <div onClick={handleScheduleCloseModal}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                          </div>

                          <div className="mt-4">
                            <form>
                              <div className="relative mt-4">
                                <label
                                  htmlFor="date"
                                  className="block mb-2 text-sm font-medium text-gray-500 ml-2"
                                >
                                  Pick a Date
                                </label>
                                <div className="relative">
                                  <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                    <svg
                                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </div>
                                  {/* Step 2: Update the state when the date changes */}
                                  <input
                                    type="date"
                                    id="date"
                                    className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    required
                                    value={selectedDate}
                                    onChange={(e) =>
                                      setSelectedDate(e.target.value)
                                    } // Update state on date change
                                  />
                                </div>
                              </div>
                            </form>

                            <button
                              type="button"
                              onClick={handleScheduleArticle}
                              className="text-white hover:bg-red-500 bg-orange-600 mt-6 w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                            >
                              {loadSchedule
                                ? "Scheduling..."
                                : "Schedule Article"}
                            </button>
                          </div>
                        </div>
                      </AppModalShort>
                    )}
                  </div>
                )}
              </>

              <>
                {showSEOReport && (
                  <div className="h-screen flex justify-center items-center">
                    <SEOReportView
                      showSEOReportStatus={showSEOReportStatus}
                      isMigrate={true}
                      isSingleArticlePublish={false}
                    />
                  </div>
                )}
              </>
            </>
          )}
        </>
      )}

      {!loading && (
        <>
          {allArticles.length < 1 && (
            <>
              {!loading && (
                <div className="flex text-center items-center justify-center -mt-16 min-h-screen">
                  <div className="bg-white rounded-xl p-12 shadow">
                    <img
                      src={EmptyImage}
                      alt="Your Image"
                      className="w-[30%] h-auto"
                    />
                    <p className={"font-bold text-gray-600 mt-8"}>
                      No Articles Found
                    </p>
                    <p className={"text-gray-600 mt-3"}>
                      Unleash your thoughts, transform your ideas into <br />a
                      masterpiece — start your article today!
                    </p>
                    <button
                      onClick={() => {
                        navigate(ROUTE_CONSTANT.CREATE_ARTICLE, {
                          state: {
                            blog_id,
                            blog_uuid,
                            blog_title,
                            blog_handle,
                          },
                        });
                      }}
                      className="bg-primary hover:bg-orange-600 text-white font-bold py-2 px-8 rounded-lg mt-6"
                    >
                      Create Article
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}

      {loading && <Lottie options={defaultOptions} height={200} width={200} />}

      {openContentRepurpose && (
                <ContentRepurpose setmodalOpen={setOpenContentRepurpose} articleObject={selectedArticle} />
            )}
    </div>
  );
}
