import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router";
import TopNav from "../components/layouts/TopNav";
import SecondaryTopNav from "../components/layouts/SecondaryTopNav";
import {JSONContent} from "novel";
import {defaultValue} from "../components/novel/default-value";
import {ThemeToggle} from "../components/novel/components/theme-toggle";
import Editor from "../components/novel/components/editor/advanced-editor";
import DynamicEditorSection from "../components/quill/DynamicEditorSection";
import PageTitle from "../components/typography/PageTitle";
import {Switch} from '@headlessui/react'
import {Fragment} from 'react'
import {Menu, MenuButton, MenuItem, MenuItems, Transition} from '@headlessui/react'
import {EllipsisHorizontalIcon} from '@heroicons/react/20/solid'
import AppModalShort from "../components/shared/AppModalShort";
import SocialMediaWidgetForm from "../components/shared/widget-form/SocialMediaWidgetForm";
import NewsletterWidgetWidgetForm from "../components/shared/widget-form/NewsletterWidgetForm";
import {STORE_UUID} from "../constants/app.constants";
import API from "../api/api";
import {toastError, toastSuccess} from "../context/ToasterContext";
import AppContext from "../context/AppContext";
import NewsletterWidgetForm from "../components/shared/widget-form/NewsletterWidgetForm";
import _ from "lodash";

const statuses = {
    Free: 'text-green-700 bg-green-50 ring-green-600/20',
    OnProgress: 'text-gray-600 bg-white ring-gray-500/10',
    Paid: 'text-red-700 bg-red-50 ring-red-600/10',
}

const initialWidgets = [
    {
        id: 1,
        name: 'Social Media Icons',
        slider: true,
    },
    {
        id: 2,
        name: 'Newsletter Popup',
        slider: true,
    },
    {
        id: 3,
        name: 'Reading Scroll Progress',
        slider: false,
    },
    {
        id: 4,
        name: 'Read Time Estimate',
        slider: false,
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Widgets() {
    
    const navigate = useNavigate();
    const [widgets, setWidgets] = useState(initialWidgets);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const location = useLocation();
    const {blog_id, blog_uuid} = location.state;
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [selectedWidget, setSelectedWidget] = useState(null);
    const [isActivated, setIsActivated] = useState(false);
    const [activatedWidgets, setActivatedWidgets] = useState({});
    const [widgetData, setWidgetData] = useState({
        social_media_widget_status: false,
        newsletter_widget_status: false,
        display_read_time_on_articles: false,
        display_read_scroll_progess_bar_on_articles: false,
    });
    const [originalWidgetData, setOriginalMediaWidgetData] = useState({});

    const account_uuid = localStorage.getItem('a_blogd_account_uuid');
    
    const toggleSlider = () => {
        setIsSliderOpen(!isSliderOpen);
    };
    
    const openWidgetSlider = (widget) => {
        setSelectedWidget(widget);
        setIsSliderOpen(true);
    };
    
    const toggleActivation = (widgetName) => {
        setWidgetData(prevState => ({
            ...prevState,
            [widgetName]: !prevState[widgetName]
        }));
        if (widgetName === 'display_read_time_on_articles') {
            handleReadTimeWidgetCreation();
        }
        if (widgetName === 'display_read_scroll_progess_bar_on_articles') {
            handleReadProgressWidgetCreation();
        }
    };
    
    useEffect(() => {
        
        token && getSocialMediaWidgets();
    }, [token, account_uuid]);
    
    const handleReadTimeWidgetCreation = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const payload = {status: !originalWidgetData.display_read_time_on_articles};
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.post(`social_widgets/add-read-time-widget/${account_uuid}/${store_uuid}/${blog_uuid}`, payload, headerParams)
            .then(res => {
                toastSuccess("Widget Saved Successfully!");
                getSocialMediaWidgets();
            })
            .catch(error => {
                toastError(error.message);
                console.log('Error: ', error.message);
            })
    }
    
    const handleReadProgressWidgetCreation = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const payload = {status: !originalWidgetData.display_read_scroll_progess_bar_on_articles};
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.post(`social_widgets/add-read-progress-widget/${account_uuid}/${store_uuid}/${blog_uuid}`, payload, headerParams)
            .then(res => {
                toastSuccess("Widget Saved Successfully!");
                getSocialMediaWidgets();
            })
            .catch(error => {
                toastError(error.message);
                console.log('Error: ', error.message);
            })
    }
    
    const getSocialMediaWidgets = () => {
        let headerParams = {
            headers: {
                authorization: token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`social_widgets/get-social-widgets/${account_uuid}/${store_uuid}/${blog_uuid}`, headerParams)
            .then(res => {
                console.log("Social Media Widgets: ", res.data);
                if(res.data.data){
                setWidgetData(res.data.data);
                setOriginalMediaWidgetData(res.data.data);
                }
            })
            .catch(error => {
                toastError(error.message);
                console.log('Error: ', error.message);
            })
    }
    
    return (
        <div>
            <TopNav/>
            <SecondaryTopNav/>
            <div className="max-w-5xl px-6 mx-auto mb-8">
                <div className="ml-3">
                    <PageTitle>Widgets</PageTitle>
                </div>
                <div role="list" className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    
                    {/* SOCIAL MEDIA WIDGET */}
                    <div className="flex items-center bg-white p-6">
                        <div className="p-4 border border-gray-900/4 rounded-lg mr-4">
                            <div className="font-bold leading-6 text-gray-900/4">
                                &nbsp;S&nbsp;
                            </div>
                        </div>
                        <div className="flex justify-between w-full">
                            <div>
                                <div className="font-semibold leading-6 text-gray-900">
                                    Social Media Icons Widget
                                </div>
                                <div className="text-sm font-medium leading-6 text-gray-500 mt-1">
                                    Loren ipsum dolor sit amet consectetur elit.
                                </div>
                                {!!widgetData.social_media_widget_status ?
                                    <div className="text-sm font-medium leading-6 text-green-500 mt-1">
                                        Active
                                    </div> :
                                    <div className="text-sm font-medium leading-6 text-red-500 mt-1">
                                        Inactive
                                    </div>}
                            </div>
                            <div className="float-right cursor-pointer"
                                 onClick={() => openWidgetSlider('social_media')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    
                    {/* NEWSLETTER WIDGET */}
                    <div className="flex items-center bg-white p-6">
                        <div className="p-4 border border-gray-900/4 rounded-lg mr-4">
                            <div className="font-bold leading-6 text-gray-900/4">
                                &nbsp;N&nbsp;
                            </div>
                        </div>
                        <div className="flex justify-between w-full">
                            <div>
                                <div className="font-semibold leading-6 text-gray-900">
                                    Newsletter Widget
                                </div>
                                <div className="text-sm font-medium leading-6 text-gray-500 mt-1">
                                    Loren ipsum dolor sit amet consectetur elit.
                                </div>
                                {!!widgetData.newsletter_widget_status ?
                                    <div className="text-sm font-medium leading-6 text-green-500 mt-1">
                                        Active
                                    </div> :
                                    <div className="text-sm font-medium leading-6 text-red-500 mt-1">
                                        Inactive
                                    </div>}
                            </div>
                            <div className="float-right cursor-pointer"
                                 onClick={() => openWidgetSlider('newsletter')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    
                    {/* READING SCROLL PROGRESS WIDGET */}
                    <div className="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
                        <div className="flex items-center bg-white p-6">
                            <div className="p-4 border border-gray-900/4 rounded-lg mr-4">
                                <div className="font-bold leading-6 text-gray-900/4">
                                    &nbsp;R&nbsp;
                                </div>
                            </div>
                            <div className="flex justify-between w-full">
                                <div>
                                    <div className="font-semibold leading-6 text-gray-900">
                                        Reading Scroll Progress
                                    </div>
                                    <div className="text-sm font-medium leading-6 text-gray-500 mt-1">
                                        Loren ipsum dolor sit amet consectetur elit.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <dl className="border-t border-gray-200 bg-white text-sm px-6 py-3 leading-6">
                            <div className="flex justify-between gap-x-4 py-3">
                                {!!widgetData.display_read_scroll_progess_bar_on_articles ?
                                    <div className="text-sm font-medium leading-6 text-green-500 mt-1">
                                        Active
                                    </div> :
                                    <div className="text-sm font-medium leading-6 text-red-500 mt-1">
                                        Inactive
                                    </div>}
                                <dd className="flex items-start gap-x-2">
                                    <button
                                        onClick={() => toggleActivation('display_read_scroll_progess_bar_on_articles')}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full ${widgetData.display_read_scroll_progess_bar_on_articles ? 'bg-orange-600' : 'bg-gray-200'}`}>
                                    <span
                                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${widgetData.display_read_scroll_progess_bar_on_articles ? 'translate-x-6' : 'translate-x-1'}`}/>
                                    </button>
                                </dd>
                            </div>
                        </dl>
                    </div>
                    
                    {/* READING TIME ESTIMATE WIDGET */}
                    <div className="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
                        <div className="flex items-center bg-white p-6">
                            <div className="p-4 border border-gray-900/4 rounded-lg mr-4">
                                <div className="font-bold leading-6 text-gray-900/4">
                                    &nbsp;R&nbsp;
                                </div>
                            </div>
                            <div className="flex justify-between w-full">
                                <div>
                                    <div className="font-semibold leading-6 text-gray-900">
                                        Reading Time Estimate
                                    </div>
                                    <div className="text-sm font-medium leading-6 text-gray-500 mt-1">
                                        Loren ipsum dolor sit amet consectetur elit.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <dl className="border-t border-gray-200 bg-white text-sm px-6 py-3 leading-6">
                            <div className="flex justify-between gap-x-4 py-3">
                                {!!widgetData.display_read_time_on_articles ?
                                    <div className="text-sm font-medium leading-6 text-green-500 mt-1">
                                        Active
                                    </div> :
                                    <div className="text-sm font-medium leading-6 text-red-500 mt-1">
                                        Inactive
                                    </div>}
                                <dd className="flex items-start gap-x-2">
                                    <button
                                        onClick={() => toggleActivation('display_read_time_on_articles')}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full ${widgetData.display_read_time_on_articles ? 'bg-orange-600' : 'bg-gray-200'}`}>
                                    <span
                                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${widgetData.display_read_time_on_articles ? 'translate-x-6' : 'translate-x-1'}`}/>
                                    </button>
                                </dd>
                            </div>
                        </dl>
                    </div>
                    
                    
                    {/* SLIDER */}
                    <div
                        className={`fixed top-0 right-0 h-screen w-2/6 bg-white border-l shadow-xl transform
                                    ${isSliderOpen ? 'translate-x-0' : 'translate-x-full'} overflow-y-auto
                                    transition-transform duration-300 ease-in-out`}>
                        <div className="p-6 mt-28 pt-8">
                            <div className="flex justify-between items-center">
                                <h3 className="ml-2 font-semibold text-gray-900">Add {_.startCase(_.replace(selectedWidget, /_/g, ' '))} Widget</h3>
                                <button className="text-gray-700 hover:text-gray-900" onClick={toggleSlider}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>
                            
                            {selectedWidget === 'social_media' &&
                                <SocialMediaWidgetForm
                                    closeWidget={toggleSlider}
                                    widgetData={widgetData.social_media_widget_data}
                                    getSocialMediaWidgets={getSocialMediaWidgets}/>}
                            
                            {selectedWidget === 'newsletter' &&
                                <NewsletterWidgetForm
                                    closeWidget={toggleSlider}
                                    widgetData={widgetData}
                                    getSocialMediaWidgets={getSocialMediaWidgets}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
